import { SCHEME_REFERENCE_TYPE } from 'src/app/scheme-constants/scheme-constants';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Constants } from 'projects/shared/src/app/shared/constants/constants';
import { CurrentUserService } from './../current-user.service';
import { SCHEME_NAME, SCHEME_TYPE } from 'projects/training-partner/src/app/training-partner/constants/string.constant';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class TotBatchService {
	batchId: any;
	private status = new BehaviorSubject<string>('NEW');
	currentStatus = this.status.asObservable();
	action: any;
	marksStatus;
	schemeTypeForSsc: any;
	constructor(private apiService: ApiService, private currentUserService: CurrentUserService) { }
	submitBatch(batchId: number, status: string) {
		const submitUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/status/${batchId}/${status}`;
		return this.apiService.patch(submitUrl);
	}

	uploadSupportingDocument(data, batchId) {
		console.log(data);
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/${batchId}/supportingdoc/assessment`
		return this.apiService.put(URL, data);
	}

	CreateToTBatch(batchDetails) {
		const createToTBatchURL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch`;
		return this.apiService.post(createToTBatchURL, batchDetails);
	}
	setSchemeType(type) {
		if (type) {
			this.schemeTypeForSsc = type;
		} else {
			this.schemeTypeForSsc = '';
		}
	}

	getSchemeType(): Observable<any> {
		return of(this.schemeTypeForSsc);
	}

	// api/tot-toa/v1 / get / loggedInActor / qpCode
	getActorBasedJobRoles() {
		const getBatchURL = `${environment.ToTServiceURL}/api/tot-toa/v1/get/loggedInActor/qpCode`;
		return this.apiService.get(getBatchURL);
	}
	updateBatch(tcData, batchId) {
		const updateBatchURL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/${batchId}/tc`;
		return this.apiService.patch(updateBatchURL, tcData);
	}

	approveBatch(approveData: any, requestIds): Observable<any> {
		const approve = { 'status': approveData.status, 'reason': approveData.reason, 'comment': approveData.comment };
		return this.currentUserService.getCurrentUser().pipe(switchMap((currentUser: any) => {
			const role = currentUser.role.replace(/ +/g, '').toLowerCase();
			const approveBatch = `${environment.ToTServiceURL}/api/tot-toa/v1/batchworkflow/${role}/${requestIds.toString()}`;
			return this.apiService.patch(approveBatch, approve);
		}));
	}

	setMarks(data) {
		console.error('bbbbbbbbbbbbbbbbbbbb', data);
		this.marksStatus = data;
	}
	setAction(data) {
		this.action = data;
	}
	getAction() {
		return this.action;
	}
	// setBatchId(data) {
	// 	console.error('bbbbbbbbbbbbbbbbbbbb', data);
	// 	this.batchId = data;
	// }
	// getBatchId() {
	// 	console.error(this.marksStatus);
	// 	return this.batchId;
	// }
	getMarks() {
		console.error(this.marksStatus);
		return this.marksStatus;
	}
	rescheduleRequest(batchId, data) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/rescheduleRequest?batchId=${batchId}`;
		return this.apiService.put(URL, data);
	}
	cancelRequest(batchId, data) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/cancelRequest?batchId=${batchId}`;
		return this.apiService.put(URL, data);
	}

	getRescheduleRequestForNSDCPMU(data) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/pmu/requests`;
		return this.apiService.post(URL, data);
	}

	// getBatches(status, pageNo = 1, itemsPerPage = Constants.RECORDSPERPAGE) {
	// 	const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch`;
	// 	return this.apiService.get(URL, {
	// 		itemsPerPage,
	// 		pageNo,
	// 		status
	// 	});
	// }
	getBatches(status = 'PENDING', pageNo = 1, itemsPerPage = Constants.RECORDSPERPAGE, searchCriterias = {}, source?) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/filter`;
		return this.apiService.post(URL, {
			status: status,
			pageNo: pageNo,
			itemsPerPage: itemsPerPage,
			filterdata: searchCriterias,
			source: source ? source : ''
		}).pipe(map(res => {
			res.batches = res.batches || []
			res.batches.map(batch => {
				batch.candidates = batch.candidates || [];
				// batch.size = batch.candidates.length || batch.size
				batch.size = batch.size

			})
			return res;
		}));
	}
	getAssessment(status, pageNo, limit, filterdata = {}) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/list/users/assessment?displayTab=${status}&pageNo=${pageNo}&limit=${limit}&sortBy=batchId&sortOrder=-1`;
		return this.apiService.get(URL);
	}
	getAssessmentBatches(status, pageNo, limit, filterdata = {}, batchFor?, batchList = 'marksAssessment') {
		batchFor = batchFor ? '&batchOf=' + batchFor : ''
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/list/users/assessment?displayTab=${status}&pageNo=${pageNo}&limit=${limit}&sortBy=assmtUpdatedOn&sortOrder=-1${batchFor}&batchList=${batchList}`;
		return this.apiService.post(URL, { filterdata: filterdata }).pipe(map(res => {
			res.data = res.data || []
			// res.data.map(batch => {
			// 	batch.size = batch.candidatesSize || batch.size
			// })
			return res;
		}));
	}

	getBatch(batchId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/${batchId}`;
		return this.apiService.get(URL).pipe(map(batch => {
			if (batch) {
				batch.assessmentStatus = batch.bAssessmentStatus || batch.assessmentStatus
				batch.candidates = batch.candidates || [];
				// batch.size = batch.candidates.length || batch.size
				batch.size = batch.size
			}
			return batch
		}));
	}
	// getSinglePendingbatch(reqId) {
	// 	const singlePendingbatch = `${
	// 		environment.ToTServiceURL
	// 		}/v1/batchworkflow/pending/${reqId}`;
	// 	return this.apiService.get(singlePendingbatch);
	// }
	getRejectedBatches(status = 'REJECTED', pageNo = 1, itemsPerPage = Constants.RECORDSPERPAGE, searchCriterias = {}) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batchworkflow/pending/`;
		return this.apiService.post(URL, {
			pageNo,
			itemsPerPage,
			status,
			filterdata: searchCriterias
		});
	}
	getJobRolesForApplicant() {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/jobrole/`;
		return this.apiService.get(URL);
	}
	getCandidatesByBatchId(batchId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/${batchId}`;
		return this.apiService.get(URL);
	}
	getJobRolesByBatchId(data) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/${data.batchId}/candidate/${data.candidateUserName}/jobrole/list`;
		return this.apiService.get(URL);
	}
	getSinglePendingbatch(batchId) {
		const singlePendingbatch = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/${batchId}`;
		return this.apiService.get(singlePendingbatch).pipe(map(batch => {
			if (batch) {
				batch.assessmentStatus = batch.bAssessmentStatus || batch.assessmentStatus
				batch.candidates = batch.candidates || [];
			}
			return batch
		}));
	}
	getPendingbatchById(batchId) {
		const singlePendingbatch = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/id/${batchId}`;
		return this.apiService.get(singlePendingbatch).pipe(map(batch => {
			if (batch) {
				batch.assessmentStatus = batch.bAssessmentStatus || batch.assessmentStatus
				batch.size = batch.candidatesSize || batch.size
				batch.candidates = batch.candidates || [];
				// batch.size = batch.candidates.length || batch.size
			}
			return batch
		}));
	}
	reschedule(data) {
		const approveCandidateUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/batch?batchId=${data.batchId}`;
		return this.apiService.put(approveCandidateUrl, data);
	}
	getBatchConfigurations(type) {
		const batchSizeUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/admin/configuration/${type}`;
		return this.apiService.get(batchSizeUrl, {
			fields: `maxBatchSize,maxDaysForBatchCreationBeforeBatchStart,minTrainingDays,minPwDTrainingDays`
		});
	}
	getBatchType() {
		const batchTyeUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/admin/batch/type`;
		return this.apiService.get(batchTyeUrl);
	}
	getBatchTypeWithOutAuth() {
		const batchTyeUrl = `${environment.ToTServiceURL}/api/tot-toa/v2/get/batchtype`;
		return this.apiService.get(batchTyeUrl);
	}
	approveMultipleCandidate(batchID, applicant, action?) {
		const approveCandidateUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/enrol/selectedapplicants`;
		return this.apiService.put(approveCandidateUrl, { batchID, applicant, action });
	}

	searchEnrollApplicants(data) {
		const searchEnrollUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/ssc/applicant/enrollment`;
		return this.apiService.post(searchEnrollUrl, data);
	}

	enrollApplicantsBySSC(data) {
		const enrollApplicantUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/ssc/applicant/enrollment`
		return this.apiService.put(enrollApplicantUrl, data);
	}

	getTrainingRequestListToLinkWithBatch(data, role) {
		if (role === 'Trainer') {
			const trainingRequestUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/trainer/trainingRequest/jobRoles`;
			return this.apiService.post(trainingRequestUrl, data);
		} else if (role === 'Assessor') {
			const trainingRequestUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/assessor/trainingRequest/jobRoles`;
			return this.apiService.post(trainingRequestUrl, data);
		}

	}

	linkTrainingRequestToBatch(data, role) {
		if (role === 'Trainer') {
			const linkTrainingRequestUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/trainer/trainingRequest/status`;
			return this.apiService.put(linkTrainingRequestUrl, data)
		} else if (role === 'Assessor') {
			const linkTrainingRequestUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/assessor/trainingRequest/status`;
			return this.apiService.put(linkTrainingRequestUrl, data)
		}
	}

	approveCandidate(batchId, userName, status = 'ACCEPTED', remarks?, role?) {
		let data;
		// data.role = role;
		if (remarks && remarks !== '') {
			data = { status: status, role: role, comment: remarks.comment, reason: remarks.reason };
		} else {
			data = { status: status, role: role }
		}
		const approveCandidateUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/${batchId}/candidates/${userName}`;
		return this.apiService.patch(approveCandidateUrl, data);
	}
	getFees(jobRoles) {
		console.log(jobRoles);
		const feesUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/admin/batch/fees`;
		return this.apiService.post(feesUrl, jobRoles);
	}
	getAssessorsByQpcode(jobRoleId: string, itemsPerPage = Constants.RECORDSPERPAGE, pageNo = 1) {
		// http://localhost:3000/v1/user/assesment-agency/assessor?pageNo=1&itemsPerPage=10&jobRoleId=AA5Role
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/user/assesment-agency/assessor`;
		return this.apiService.get(URL, {
			itemsPerPage,
			pageNo,
			jobRoleId
		});
	}
	getCandidateDetails(userRole, userName) {
		const candidateUrl = `${environment.userServiceUrl}/api/user/v1/${userRole}/username/${userName}`;
		return this.apiService.get(candidateUrl);
	}
	batchApproval(batchId) {
		const candidateUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/status/${batchId}/submitted `;
		return this.apiService.patch(candidateUrl);
	}

	getApplicantcertificateDetails(data) {
		const certReqId = data.preferences ? data.preferences.linkedCertReqId : data.linkedCertReqId;
		const certificateUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/get/certificate/applicants?certReqId=${certReqId}&userName=${data.userName}`;
		return this.apiService.get(certificateUrl);
	}
	getStatus(status: string) {
		this.status.next(status);
	}
	approveMarksheet(batchId, candidateId, jobRoleId, status, data) {
		const URL = `${environment.ToTServiceURL}/SSCDomainApproval/${batchId}/${candidateId}/${jobRoleId}/${status}`;
		return this.apiService.put(URL, data);
	}
	getTcConductedBatches(tcId) {
		const url = `${environment.ToTServiceURL
			}/api/tot-toa/v1/batch/tcConductedBatches/${tcId}`;
		return this.apiService.get(url);
	}
	getapplicantDataBySearch(searchData, pageNo, size) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/applicant/search?keyword=
		${searchData.keyword}&sortBy=${searchData.sortBy}&sortOrder=-1&pageNo=${pageNo}&size=${size}`;
		return this.apiService.post(URL, searchData);
	}
	updateApplicantPreferences(data) {
		const URL = `${environment.userServiceUrl}/api/user/v1/applicant/preferences`;
		return this.apiService.post(URL, data);
	}
	searchFormData(searchData) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/get/trainingrequest/applicants`;
		return this.apiService.post(URL, searchData);
	}

	// deleteApplicantPreferences(data) {
	// 	const URL = `${environment.userServiceUrl}/api/user/v1/applicant/preferences?trainingReqId=${data.trainingReqId}`;
	// 	return this.apiService.put(URL);
	// }

	deleteApplicantPreferences(data, type?) {
		console.log('Typeeeee', type)
		if (type === undefined) {
			type = 'trainer'
		}
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/${type}/remove/candidate/update/status`;
		return this.apiService.put(URL, data);
	}

	sendBackatch(batchId, remarks) {
		const sendBackBatch = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ssc/sendBack?batchId=${batchId}`;
		return this.apiService.put(sendBackBatch, remarks);
	}
	approveBatchDetails(batchId) {
		const approveBatch = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/status/${batchId}/approved?attendance=ok`;
		return this.apiService.patch(approveBatch);
	}
	// getAcceptedBatches(status, pageNo = 1, itemsPerPage = Constants.RECORDSPERPAGE) {
	// 	const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/tc/batches/${status}`;
	// 	return this.apiService.get(URL, {
	// 		itemsPerPage,
	// 		pageNo,
	// 		status
	// 	});
	// }
	getAcceptedBatches(status, pageNo = 1, itemsPerPage = Constants.RECORDSPERPAGE, searchCriterias = {}) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/tc/batches/${status}`;
		const reqObj = {
			itemsPerPage: itemsPerPage,
			pageNo: pageNo,
			status: status,
			filterdata: searchCriterias
		}
		return this.apiService.post(URL, reqObj);
	}
	getBatchStatus(status?) {
		const type = status ? status : ''
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/admin/batch/filterdata?type=${type}`;
		return this.apiService.get(URL);
	}

	// getBatchStatus(status) {
	// 	const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/admin/batch/statuses?type=${status}`;
	// 	return this.apiService.get(URL);
	// }

	getMarkSheetDetails(batchId, userName, qpCode) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.batchServiceUrl}/api/v1/cert/marksheet?for=tot&howMany=one&batchId=${batchId}&userName=${userName}&qpCode=${qpCode}`;
		return this.apiService.get(URL);
	}

	getCertificateDetails(batchId, userName, qpCode) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate?for=tot&howMany=one&batchId=${batchId}&userName=${userName}&qpCode=${qpCode}`;
		return this.apiService.get(URL);
	}

	getCertificateDetail(batchId, userName) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/${batchId}/candidate/${userName}`;
		return this.apiService.get(URL);
	}

	getAllTrainingModels() {
		const url = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/type/all`;
		return this.apiService.get(url);
	}


	updateBulkRequest(id, data?) {
		const updatedMarkSheetBulk = data && data.updatedMarkSheetBulk ? data.updatedMarkSheetBulk : 'No'
		console.log(updatedMarkSheetBulk);
		const apiUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/bulk/batch/${id}?updatedMarkSheetBulk=${updatedMarkSheetBulk}`;
		return this.apiService.put(apiUrl);
	}

	mapType(type: string, schemeType: string, forcedSchemeName?: string, isCSSMBatch?: any) {
		let isCSSMBatchType = isCSSMBatch ? 'CSSM' : 'CSCM';
		if (isCSSMBatch === '' || isCSSMBatch === undefined) {
			isCSSMBatchType = ''
		}
		if (type === SCHEME_TYPE.PMKVY) {
			schemeType = forcedSchemeName ? forcedSchemeName + ' ' + schemeType :
				SCHEME_NAME.PMKVY + ' ' + schemeType + ' ' + isCSSMBatchType;
			return schemeType
		} else if (type === SCHEME_REFERENCE_TYPE.NON_PMKVY_CSSM.name) {
			schemeType = SCHEME_REFERENCE_TYPE.NON_PMKVY_CSSM.display
			return schemeType;
		}
		return forcedSchemeName || type
	}

	updateCount(data, type) {
		const url = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/${data.batchId}
			/candidate/${data.candidateUserName}/jobrole?qpCode=${data.qpCode}&docType=${type}`;
		console.log(url, data);
		return this.apiService.put(url, data)
	}

	getPmkvyBatchList(status, data?) {
		// http://localhost:3000/api/batch/v1/get/batches/specific/loginrole?itemsPerPage=1&pageNo=87
		const trainingType = data && data.trainingType ? data.trainingType : ''
		const schemeType = data && data.schemeType ? data.schemeType : ''
		const jobRole = data && data.jobRole ? data.jobRole : ''
		const state = data && data.state ? data.state : ''
		const district = data && data.district ? data.district : ''
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/batches/specific/loginrole?
		trainingType=${trainingType}&schemeType=${schemeType}
		&jobRole=${jobRole}&state=${state}&district=${district}&status=${status}`;
		return this.apiService.get(URL);
	}

	// http://localhost:3000/api/batch/v1/assessment/assessor/feedback/pmkvy?batchId=1815&tcId=CAMPS_000018

	tcFeedback(data, params) {
		const url = `${environment.userServiceUrl}/api/batch/v1/assessment/assessor/feedback/pmkvy?batchId=${params.batchId}&tcId=${params.tcId}`
		return this.apiService.post(url, data);
	}

	getTCFeedback(data) {
		// http://localhost:3000/api/batch/v1/get/assessment/assessor/feedback/pmkvy?batchId=1075
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/assessment/assessor/feedback/pmkvy?batchId=${data.batchId}`
		return this.apiService.get(URL)
	}
	genrateSkillCard(canId, batchId, sectorId, jobRoleName) {
		const URL = `${environment.pdfGenerate}/api/v1/generatepdfreports/skillcard?candId=${canId}&batchId=${batchId}&sectorId=${sectorId}&jobRoleNm=${jobRoleName}`;
		return this.apiService.get(URL)
	}
	genrateSkillCardV1(canId, batchId, sectorId, jobRoleName, qpCode, version) {
		const encodedQpCode = encodeURIComponent(qpCode)
		const URL = `${environment.pdfGenerate}/api/v1/generatepdfreports/skillcard?candId=${canId}&batchId=${batchId}&sectorId=${sectorId}&jobRoleNm=${jobRoleName}&qpCode=${encodedQpCode}&version=${version}`;
		return this.apiService.get(URL)
	}

	downloadExcelForTrainer(data) {
		const url = `${environment.excelService}/api/excel-report/v1/tottoa/get/trainer/basedonjobroles`;
		return this.apiService.getBlobOnArguments(url, data);
	}

	downloadExcelForAssessor(data) {
		const url = `${environment.excelService}/api/excel-report/v1/tottoa/get/assessor/basedonjobroles`;
		return this.apiService.getBlobOnArguments(url, data);
	}

	updateMobileOrEmailforUser(email, mobile) {
		const phone = Number(mobile)
		const url = `${environment.ToTServiceURL}/api/tot-toa/v1/applicant/email/mobile/update`;
		return this.apiService.put(url, {
			email,
			phone
		})
	}

	resetAssmtCandidateMarks(data) {
		// tslint:disable-next-line: max-line-length
		const url = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/resetAssmtCandidateMarks?batchId=${data.batchId}&qpCode=${data.qpCode}&candidateUserName=${data.candidateUserName}`;
		return this.apiService.put(url, data);
	}

	acceptBatchForOfflineAssmnt(batchId, qpCode, version, payload) {
		const encodeQpCode = encodeURIComponent(qpCode);
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/assessment/offline/set/${batchId}?qpCode=${encodeQpCode}&version=${version}`;
		return this.apiService.put(URL, payload);
	}

	getOfflineBatch(pageNo, limit, displayTab) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/list/users/assessment?displayTab=${displayTab}&pageNo=${pageNo}&limit=${limit}&sortBy=assmtUpdatedOn&sortOrder=-1&batchList=offlineAssessment`;
		return this.apiService.post(URL);
	}

	uploadEvidence(batchId, qpCode, version, status, payLoad) {
		const encodedQpCode = encodeURIComponent(qpCode);
		const URL = ` ${environment.ToTServiceURL}/api/tot-toa/v1/batch/assessment/offline/doc/upload/${batchId}?qpCode=${encodedQpCode}&version=${version}&status=${status}`;
		return this.apiService.put(URL, payLoad);
	}

	submitReason(batchId, qpCode, version, payload) {
		const encodedQpCode = encodeURIComponent(qpCode);
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/assessment/offline/reason/${batchId}?qpCode=${encodedQpCode}&version=${version}`
		return this.apiService.put(URL, payload);
	}

	getAuditTrails(batchId, qpCode, version, actionRole) {
		const encodeQpCode = encodeURIComponent(qpCode)
		let queryParams = { 'qpCode': qpCode, 'version': version, 'actionByRole': actionRole };
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/assessment/offline/fetchAuditLog/${batchId}`;
		return this.apiService.get(URL, queryParams);
	}

	getBatchTrainerAssessorDetails(batchId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/trainer/${batchId}`
		return this.apiService.get(URL);
	}

	getBatchMateDetails(batchId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/mates/${batchId}`
		return this.apiService.get(URL);
	}

	checkBatchLinkageForTrainer() {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/trainer/get/batchLinkage`
		return this.apiService.get(URL);
	}

	checkBatchLinkageForAssessor() {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/assessor/get/batchLinkage`
		return this.apiService.get(URL);
	}

	submitDeactivationRequestForTrainer(payload) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/trainer/deactivate/request`
		return this.apiService.post(URL, payload);
	}

	submitDeactivationRequestForAssessor(payload) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/assessor/deactivate/request`
		return this.apiService.post(URL, payload);
	}

	getDeactivationRequestForNSDCPMU(payload?) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/applicant/deactivate/requests`
		return this.apiService.get(URL, payload)
	}

	actionOnApplicantProfileDeactivationRequest(userName, payload) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/applicant/deactivate/request/${userName}`;
		return this.apiService.put(URL, payload)
	}

	getApplicantInformation(userId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/applicant/detail/${userId}`;
		return this.apiService.get(URL);
	}

	getDeactivationRequestStatusForApplicant(role) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/${role}/deactivate/requests`
		return this.apiService.get(URL)
	}

	reSubmitDeactivationRequestForTrainer(payload, role) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/${role}/deactivate/request`
		return this.apiService.put(URL, payload);
	}

	generateDeactivationRequestExcel(payload) {
		console.log(payload);
		let queryParams = 'download=true';
		for (const key in payload) {
			if (key) {
				queryParams += `&${key}=${payload[key]}`
			}
		}
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/applicant/deactivate/requests?${queryParams}`
		return this.apiService.getBlob(
			URL,
			"applicant/vnd.ms-excel",
		);
	}

	updateOjtTrainingDurationForAttendance(payload,batchId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ojt/duration/${batchId}`;
		return this.apiService.post(URL, payload);
	}

	saveAndUpdateOjtTrainingAttendance(payload,batchId,status) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ojt/sessions/${batchId}?status=${status}`;
		return this.apiService.post(URL, payload);
	}

	getUpdatedOjtTrainingAttendance(batchId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ojt/sessions/${batchId}`;
		return this.apiService.get(URL);
	}
	getTrainerAssessorForPMKVY(data){
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/get/${data.type}?pageNo=${data.pageNo}&limit=${data.limit}&userName=${data.userName}`;
		return this.apiService.get(URL);
	}

	uploadOjtTrainingAttendanceProof(batchId, url) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ojtTrainingAttendanceProofDocument/${batchId}`;
		return this.apiService.put(URL, {attendanceProofDoc: url});
	}

	getAssessedTargetPercentage(projectId){
		const url = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/all/assessedTargetPercentage/project/${projectId}`;
		return this.apiService.get(url);
	}

	getTrainerList(queryParams){
		const url = `${environment.ToTServiceURL}/api/tot-toa/v1/get/trainer?pageNo=${queryParams.pageNo}&limit=${queryParams.limit}&userName=${queryParams.userName}`;
		return this.apiService.get(url);
	}

}

