import { Injectable, EventEmitter } from "@angular/core";
import { ApiService } from "./api.service";
import { environment } from "src/environments/environment";
import { Constants } from "projects/shared/src/app/shared/constants/constants";
import { SCHEME_TYPE } from "projects/training-partner/src/app/training-partner/constants";
import { Helpers } from "projects/training-calendar/src/app/helpers";
import {
	PMKVY_SCHEME_TYPES,
	BATCH_TYPE,
	TRAINING_TYPES,
} from "projects/training-partner/src/app/training-partner/constants/string.constant";
import { TotBatchService } from "./tot-batch.service";
import {
	MOUHA_BATCH_TYPE_DISPLAY,
	PMKVY_SCHEME_VERSIONS,
	PMKVY_TRAINING_TYPES,
	RPL_CEREMONY,
	SCHEME_NAME_CONSTANT,
	SCHEME_REFERENCE_TYPE,
	USER_ROLES,
} from "src/app/scheme-constants/scheme-constants";
// import { Observable } from 'rxjs';
import * as _ from "lodash";
import { map, Observable } from "rxjs";
import { SCHEME_DAYS_COUNT } from "src/app/constant/assessmentSchemeDaysConstants";
import { PMU_STATUSES } from "src/app/constant/pmu-statuses";
import { B_ASSESSMENT_STATUS } from "projects/shared/src/app/shared/batch.table";
import { STRING } from "src/app/constant/string";

export interface IAssessmentDateCR {
	assessmentStartDate?: any;
	assessmentEndDate?: any;
	reason?: string;
	comment: string;
	sscComment?: string;
}

export enum CRActionStatus {
	ACCEPTED = "ACCEPTED",
	REJECTED = "REJECTED",
	Rejected = "Rejected",
	Accepted = "Accepted",
}

export interface IAssessorCR {
	qpCode: string;
	comment?: string;
}

interface IAssessmentAgencyCR {
	qpCode: string;
	comment?: string;
}

interface IAssessmentDateCRPatch {
	sscComment?: string;
	actionStatus?: CRActionStatus;
	assessmentStartDate?: Date;
	assessmentEndDate?: Date;
}

interface IAssessorCRPatch {
	qpCode: string;
	sscComment?: string;
	previousAssessor: {};
	newAssessor: {};
	actionStatus: CRActionStatus;
	reqId: any;
}

interface IAssessmentAgencyCRPatch {
	qpCode: string;
	sscComment?: string;
	actionStatus: CRActionStatus;
	reqId: any;
}

export interface IBatchEndDateCR {
	batchEndDate: Date;
	comment?: string;
	reason?: string;
}

interface IBatchEndDateCRPatch {
	batchEndDate?: Date;
	sscComment?: string;
	actionStatus: CRActionStatus;
}

export interface IBatchTimingsCR {
	batchStartTime: Date;
	batchEndTime: Date;
	reason?: string;
	comment?: string;
}

interface IBatchTimingsCRPatch {
	batchStartTime?: Date;
	batchEndTime?: Date;
	sscComment: string;
	actionStatus: CRActionStatus;
}
export interface ITrainerDetailsCR {
	qpCode: string;
	comment?: string;
}

interface ITrainerDetailsCRPatch {
	qpCode: string;
	sscComment?: string;
	actionStatus: CRActionStatus;
}

@Injectable({ providedIn: 'root' })
export class BatchService {
	// public setWhatToShow1 = new EventEmitter();
	// public setWhatToShow2 = new EventEmitter();
	public what;
	public jobrole;
	public BiceName;
	public showTab;
	public assessor;
	public emitValue = new EventEmitter();
	certificate: any;
	constructor(
		private apiService: ApiService,
		private totBatchService: TotBatchService
	) { }

	setCandidate(data) {
		this.what = data;
		console.log(data);
	}
	getCandidate() {
		console.log(this.what);
		return this.what;
	}
	setJobRole(data) {
		this.jobrole = data;
	}
	getJobRole() {
		return this.jobrole;
	}
	setBiceName(data) {
		this.BiceName = data;
		console.log(this.BiceName);
	}
	getBiceName() {
		return this.BiceName;
	}
	setTab(data) {
		this.showTab = data;
	}
	getTab() {
		return this.showTab;
	}

	getBatchType(batch) {
		let batchTypeDisplay = batch.batchType;
		let isMohuaScheme = false;
		if (
			batch &&
			batch.schemeBasicDetails &&
			batch.schemeBasicDetails.schemeName.id ===
			SCHEME_NAME_CONSTANT.MOHUA_RPL_TYPE_2.id &&
			batch.schemeBasicDetails.schemeReferenceType.id ===
			SCHEME_REFERENCE_TYPE.MSDE.id &&
			batch.schemeBasicDetails.trainingType.id == TRAINING_TYPES.RPL.ID
		) {
			isMohuaScheme = true;
		} else {
			isMohuaScheme = false;
		}
		if (
			(batch &&
				batch.schemeRules &&
				batch.schemeRules.assessmentDetails &&
				batch.schemeRules.assessmentDetails.isAbsentIgnore) ||
			isMohuaScheme
		) {
			if (batch.batchType == BATCH_TYPE.REGULAR) {
				batchTypeDisplay = MOUHA_BATCH_TYPE_DISPLAY.INITIAL_ASSESSMENT;
			} else if (batch.batchType == BATCH_TYPE.REASSESSMENT) {
				batchTypeDisplay = MOUHA_BATCH_TYPE_DISPLAY.FINAL_ASSESSMENT;
			}
		}
		return batchTypeDisplay;
	}

	getMonitoringBatches(pageNo: any, limit: any, filter: any, role? : any,isFromCmaActions?, isMonitoringRequest?) {
		let url, query;

		// filter = Object.assign({}, filter, {
		// 	'type': 'pmkvy'
		// })
		console.log(filter);
		const objJsonB64 = window.btoa(JSON.stringify(filter));
		query = {
			pageNo: pageNo,
			query: objJsonB64,
			limit: limit,
		};
		if(role && role === USER_ROLES.CMA_HEAD && !isMonitoringRequest){
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/batchescmahead`;
		}else if(isFromCmaActions || isMonitoringRequest){
			url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/batchescmaspoc`;
		} else{
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/batches`;
		}
		return this.apiService.get(url, query);
	}

	getMonitoringBatchDetails(batchId: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/batch/${batchId}`;
		return this.apiService.get(url);
	}

	getBatchesForPayDeduct(data: any, filter?) {
		let url;

		if (filter) {
			url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/batchesOfScn/${data}?${filter}`;
		} else {
			url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/batchesOfScn/${data}`;
		}
		return this.apiService.get(url);
	}

	getProjectlist(filters) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/tpProjects`;
		console.log(url);
		return this.apiService.get(url, filters);
	}

	payDeduct(body: any, isFromCmaActions?) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/paymentDeduction`;
		if(isFromCmaActions){
			url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/paymentDeductionnew`;
		}
		return this.apiService.post(url, body);
	}

	payDeductAndRevocation(body: any, isFromCmaActions?) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/paymentDeductionWithTargetRevocation`;
		if(isFromCmaActions){
			url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/paymentDeductionWithTargetRevocationnew`;
		}
		return this.apiService.post(url, body);
	}

	getAllCandidates(filter: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getCandidates?${filter}`;
		return this.apiService.get(url);
	}

	studentVerification(body: any, type: string) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/pushCandidateDataForVerification/${type}`;
		return this.apiService.post(url, body);
	}

	scheduleSurpriseVisit(data: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/scheduleVisit`;

		return this.apiService.post(url, data);
	}

	scheduleVirtualVisit(data: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/scheduleVV`;

		return this.apiService.post(url, data);
	}

	reScheduleVirtualVisit(data: any, id) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/updateVVisit/${id}`;
		return this.apiService.put(url, data);
	}

	getVisitByTcId(tcId: any, isCompleted?: boolean, data?: any) {
		let url;
		if (isCompleted && isCompleted === true) {
			url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getSurpriseVisitByTcId/${tcId}`;
			return this.apiService.get(url, data);
		} else {
			url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getSurpriseVisitByTcId/${tcId}`;
			return this.apiService.get(url);
		}
	}

	getVirtualVisitByTcId(tcId: any, isCompleted?: boolean, data?: any) {
		let url;
		if (isCompleted && isCompleted === true) {
			url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getVVisitByTcId/${tcId}`;
			return this.apiService.get(url, data);
		} else {
			url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getVVisitByTcId/${tcId}`;
			return this.apiService.get(url);
		}
	}

	getNonpmkvySubschemes(schemeName) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getNonPmkvySubSchemes/${schemeName}`;
		console.log(URL);
		return this.apiService.get(URL);
	}

	rescheduleSurpriseVisit(data: any, id: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/updateVisit/${id}`;
		return this.apiService.put(url, data);
	}
	cancelSurpriseVisit(data: any, id: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/cancelVisit/${id}`;
		return this.apiService.put(url, data);
	}

	cancelVirtualVisit(data: any, id: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/cancelVVisit/${id}`;
		return this.apiService.put(url, data);
	}

	completeSurpriseVisit(id) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/completeVisit/${id}`;
		return this.apiService.put(url);
	}

	issueNotice(data: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/issueScn`;
		return this.apiService.post(url, data);
	}

	getAllScheduleSurpriseVisit(filter: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getAllVisits?${filter}`;
		return this.apiService.get(url);
	}

	getDiscrepancyList(trainingType: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getDiscrepancies/${trainingType}`;
		return this.apiService.get(url);
	}

	getNoticeDetails(scnId: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getSCNbyId/${scnId}`;
		return this.apiService.get(url);
	}

	getInspectorDetails() {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getAllInspector`;
		return this.apiService.get(url);
	}

	getSurpriseVisitInput(visitId: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getVisitById/${visitId}`;
		return this.apiService.get(url);
	}

	getVirtualVerificationInput(visitId: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getVVById/${visitId}`;
		return this.apiService.get(url);
	}

	revokeTarget(data: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/requestTargetRevocation`;
		return this.apiService.post(url, data);
	}

	addcandidatesThroughFtp(exturl: string) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/${exturl}`;
		return this.apiService.post(url, {});
	}

	getTargetDetails(
		tpId: any,
		projectId: any,
		tcId: any,
		schemeType: any,
		scnId: any
	) {
		let url;
		const query = {
			tpId: tpId,
			projectId: projectId,
			tcId: tcId,
			schemeType: schemeType,
			scnId: scnId,
		};
		url = `${environment.userServiceUrl}/api/user/v1/tc/targets`;
		return this.apiService.get(url, query);
	}
	getRplTargetDetails(tpId: any, projectId: any, tcId: any, scnId: any) {
		let url;
		const query = {
			projectId: projectId,
			tcId: tcId,
			tpId: tpId,
			scnId: scnId,
		};
		url = `${environment.userServiceUrl}/api/user/v1/rpl/tc/targets`;
		return this.apiService.get(url, query);
	}

	getScnDetails(tcId, trainingType, role) {
		let url;
		const data = {
			scnBasedOn: "centerSurpriseVisit",
		};

		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getSCN/${tcId}`;
		return trainingType === "feebased" &&
			(role === "Continuous Monitoring" || role === "CMA HEAD FB")
			? this.apiService.get(url, data)
			: this.apiService.get(url);
		// return this.apiService.get(url);
	}

	assignAssementAgency(batchId: string, batch: any) {
		const getBatchDetails = `${environment.userServiceUrl}/api/batch/v1/scheme/${SCHEME_TYPE.FEE_BASED}/qpCode?batchId=${batchId}`;
		// batch.bAssessmentStatus = batch.assessmentStatus || batch.bAssessmentStatus;
		return this.apiService.put(getBatchDetails, batch);
	}

	seekClarification(body: any) {
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/actionTakenByCMASpocOnSCN`;
		return this.apiService.post(url, body);
	}

	provideJustification(body: any) {
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/actionTakenByTPOnSCN`;
		return this.apiService.post(url, body);
	}

	actionTaken(body: any, isFromCmaActions?) {
		let url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/actionTakenByCMAHeadOnSCN
		`;
		if(isFromCmaActions){
			url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/actionTakenBySpocOnSCN`;
		}
		return this.apiService.post(url, body);
	}

	dbtBharatFormSubmit(file) {
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/pmv/formSubmitBharathDbt`;
		const formData = new FormData();
		console.log(file)
		formData.append('file', file);
		return this.apiService.post(url, formData);
		// return this.apiService.post(url, body);
	}

	getBatch(batchId: any, payload: any, qpCode: string) {
		const getBatch = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/ams/changesAssessmentDates/${batchId}/${qpCode}`;
		return this.apiService.put(getBatch, payload);
	}

	getAllTC(sCode: any, trainingType: any, subscheme: any, filter: any,role?:any, isFromCmaActions?:any, isMonitoringRequest?:boolean, isSentBackRequests?:boolean) {
		let getAllTC=''
		if(role &&  role === USER_ROLES.CMA_HEAD){
			getAllTC = `${environment.pmkvycmServiceUrl
			}/api/v2/batchservice/cm/tcProjectscmahead/${sCode}/${trainingType}/${subscheme || "NA"
			}?${filter}`;
		}else if(isFromCmaActions || isFromCmaActions === 'true' || isMonitoringRequest || isSentBackRequests){
			getAllTC = `${environment.pmkvycmServiceUrl
			}/api/v2/batchservice/cm/tcProjectscmaspoc/${sCode}/${trainingType}/${subscheme || "NA"
			}?${filter}`;
		} else{
			getAllTC = `${environment.pmkvycmServiceUrl
			}/api/v2/batchservice/cm/tcProjects/${sCode}/${trainingType}/${subscheme || "NA"
			}?${filter}`;
		}
		return this.apiService.get(getAllTC);
	}

	getTCDetails(sCode: any, trainingType: any, subscheme: any, query: any,role?:any) {
		// const getTCDetails = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/tcProjects/${sCode}/${trainingType}/${subscheme}?${query}&getDetails=true`;
		// return this.apiService.get(getTCDetails);
		let getTCDetails=''
		if(role &&  role === USER_ROLES.CMA_HEAD){
			getTCDetails = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/tcProjectscmahead/${sCode}/${trainingType}/${subscheme}?${query}&getDetails=true`;
		} else{
			getTCDetails = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/tcProjects/${sCode}/${trainingType}/${subscheme}?${query}&getDetails=true`;
		}
		return this.apiService.get(getTCDetails);
	}

	patchFeeBasedBatch(batchId: string, batch: any, schemType?, sidInterestEnrollmentCancel?) {
		let url;
		if (sidInterestEnrollmentCancel) {
			url = `${environment.userServiceUrl}/api/batch/v1/scheme/${schemType ? SCHEME_TYPE.PMKVY : SCHEME_TYPE.FEE_BASED}/${batchId}?sidInterestEnrollmentCancel=${sidInterestEnrollmentCancel}`;
		} else {
			url = `${environment.userServiceUrl}/api/batch/v1/scheme/${schemType ? SCHEME_TYPE.PMKVY : SCHEME_TYPE.FEE_BASED}/${batchId}`;
		}
		batch.bAssessmentStatus =
			batch.assessmentStatus || batch.bAssessmentStatus;
		return this.apiService.patch(url, batch);
	}

	patchFeeBasedBatchJobRole(
		batchId: string,
		qpCode: string,
		jobRolePatch: any,
		status?
	) {
		const query = Helpers.serialize({
			qpCode,
		});
		const getBatchDetails = `${environment.userServiceUrl}/api/batch/v1/scheme/${SCHEME_TYPE.FEE_BASED}/${batchId}/qpCode?${query}&status${status}`;
		return this.apiService.patch(getBatchDetails, jobRolePatch);
	}

	sscAcceptRejectBatch(batchId: string, data: any) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/${batchId}`;
		return this.apiService.put(URL, data);
	}

	getTrainerlInkedWIthBatch(data, trainerId, batchId?): Observable<any> {
		const a = {};
		a["batchStartDate"] = (data && data.fromDate) || "";
		a["batchEndDate"] = (data && data.toDate) || "";
		// tslint:disable-next-line:no-unused-expression
		batchId ? (a["batchId"] = JSON.parse(batchId)) : "";
		const url = `${environment.userServiceUrl}/api/batch/v1/trainer/${trainerId}/associated/batches`;
		return this.apiService.post(url, a);
	}

	getTrainerlInkedWIthBatchWithPagination(
		pageNo,
		limit,
		data,
		trainerId,
		batchId?
	): Observable<any> {
		const a = {};
		a["pageNo"] = pageNo;
		a["limit"] = limit;
		a["batchStartDate"] = (data && data.fromDate) || "";
		a["batchEndDate"] = (data && data.toDate) || "";
		// tslint:disable-next-line:no-unused-expression
		batchId ? (a["batchId"] = JSON.parse(batchId)) : "";
		const url = `${environment.userServiceUrl}/api/batch/v1/trainer/${trainerId}/associated/batches?pageNo=${pageNo}&limit=${limit}`;
		return this.apiService.post(url, a);
	}

	getBatchesonSSC(pageNo, limit, filterdata = {}, category?, displayTab?) {
		const dispTab = displayTab ? displayTab : "PENDING";
		const cardType =
			category === "TPTC"
				? "batchRequestNonTotToa"
				: "batchRequestNonTotToaCr";
		const url = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/list/users/assessment?displayTab=${dispTab}&pageNo=${pageNo}&limit=${limit}&sortBy=batchId&sortOrder=-1&batchOf=non-tot&batchList=${cardType}`;
		// const resArray = [];
		if (filterdata && filterdata["state"] && filterdata["state"]["code"]) {
			filterdata["state"] = filterdata["state"]["code"];
		}
		if (
			filterdata &&
			filterdata["district"] &&
			filterdata["district"]["code"]
		) {
			filterdata["district"] = filterdata["district"]["code"];
		}
		return this.apiService.post(url, { filterdata: filterdata });
		// res = res || {}
		// res.data = res.data || [];
		// if (reqType) {
		// 	res.data = _.filter(res.data, num => {
		// 		if ((num.bAssessmentStatus === 'AA REJECECTED SSC REQ') && (num.cronCompleted &&
		// 			num.cronCompleted.aaAutoReversal && num.cronCompleted.aaAutoReversal.updated)) {
		// 			num.assessmentStatus = 'Auto Reversed'
		// 		} else if (num.bAssessmentStatus.includes('_CR')) {
		// 			num.assessmentStatus = num.assessmentStatus || num.bAssessmentStatus
		// 		} else {
		// 			num.assessmentStatus = num.bAssessmentStatus || num.assessmentStatus
		// 		}
		// 		if (Array.isArray(reqType)) {
		// 			reqType.filter(type => {
		// 				if (num.bAssessmentStatus.includes('_CR')) {
		// 					if (num.assessmentStatus === type) {
		// 						resArray.push(num)
		// 					}
		// 				} else {
		// 					if (num.bAssessmentStatus === type) {
		// 						resArray.push(num)
		// 					}
		// 				}
		// 			})
		// 		} else {
		// 			return (num.bAssessmentStatus.includes('_CR') ? num.assessmentStatus === reqType : num.bAssessmentStatus === reqType);
		// 		}
		// 	})
		// } else {
		// 	res.data.map(batch => {
		// 		if ((batch.bAssessmentStatus === 'AA REJECECTED SSC REQ') && (batch.cronCompleted &&
		// 			batch.cronCompleted.aaAutoReversal && batch.cronCompleted.aaAutoReversal.updated)) {
		// 			batch.assessmentStatus = 'Auto Reversed'
		// 		}
		// 		if (category === 'CR') {
		// 			batch.assessmentStatus = batch.bAssessmentStatus || batch.assessmentStatus
		// 		} else {
		// 			if (batch.bAssessmentStatus.includes('_CR')) {
		// 				batch.assessmentStatus = batch.assessmentStatus || batch.bAssessmentStatus
		// 			} else {
		// 				batch.assessmentStatus = batch.bAssessmentStatus || batch.assessmentStatus
		// 			}
		// 		}
		// 	})
		// 	// return res
		// }
		// if (resArray && resArray.length !== 0) {
		// 	res.data = resArray
		// 	return res
		// } else {
		// 	return res
		// }
	}

	getFeeBasedBatchByStatus(
		{
			status,
			bAssessmentStatus,
		}: // bQueries
			{
				status: string[];
				bAssessmentStatus?: string[];
				// bQueries?: string[]
			} = {
				status: [STRING.EMPTY],
				bAssessmentStatus: [STRING.EMPTY],
				// bQueries: [STRING.EMPTY],
			},
		{ pageNo = 1, limit = 10, noPagination = false } = {},
		schemeType?,
		filter?,
		category?,
		reqType?,
		displayCard?
	) {
		let schemeLevelType;
		if (
			filter &&
			filter.schemeName &&
			filter &&
			filter.schemeName.includes("CSSM")
		) {
			schemeLevelType = "CSSM";
		} else if (
			filter &&
			filter.schemeName &&
			filter &&
			filter.schemeName.includes("CSCM")
		) {
			schemeLevelType = "CSCM";
		} else {
			schemeLevelType = "";
		}
		const query: any = {
			bAssessmentStatus: status || STRING.EMPTY,
			// bAssessmentStatus: bAssessmentStatus || STRING.EMPTY,
			for: "ssc-assessment",
			sscStatus: bAssessmentStatus || STRING.EMPTY,
			batchType: filter && filter.requestType ? filter.requestType : "",
			stateId: filter && filter.stateId ? filter.stateId : STRING.EMPTY,
			aaAutoReversal:
				filter && filter.aaAutoReversal ? filter.aaAutoReversal : false,
			schemeLevelBatchType: schemeLevelType,
			displayCard: displayCard,
			// query: 'jobRoles.assessmentAgencyDetails.status:REJECTED',
			// queries: bQueries,
			// sscStatus: status || STRING.EMPTY,
		};
		console.log(status);
		if (!noPagination) {
			query.pageNo = pageNo;
			query.itemsPerPage = limit;
		}
		// const schemeTypes = schemeType ? schemeType : [SCHEME_TYPE.FEE_BASED, SCHEME_TYPE.STT, SCHEME_TYPE.RPL, SCHEME_TYPE.NEWSP];
		let schemeTypes;
		if (filter && filter.schemeName) {
			if (filter.schemeName.includes(SCHEME_TYPE.STT)) {
				schemeTypes = SCHEME_TYPE.STT;
			} else if (filter.schemeName.includes(SCHEME_TYPE.NEWSP)) {
				schemeTypes = SCHEME_TYPE.NEWSP;
			} else {
				schemeTypes = filter.schemeName;
			}
		} else {
			schemeTypes = [
				SCHEME_TYPE.STT,
				SCHEME_TYPE.RPL,
				SCHEME_TYPE.NEWSP,
				SCHEME_TYPE.FEE_BASED,
			]; // SCHEME_TYPE.FEE_BASED,
		}
		const filters =
			filter && filter.searchKeyword
				? "batchId=" + filter.searchKeyword + "&"
				: "";
		const url = `${environment.userServiceUrl}/api/batch/v1/scheme/${schemeTypes
			// SCHEME_TYPE.FEE_BASED
			}?${filters}${Helpers.serialize(query)}`;
		const resArray = [];
		// }?for=ssc-assessment&sscStatus=assigned&pageNo=${this.pendingBatches.pagination.pageNo}
		// &itemsPerPage=${this.pendingBatches.pagination.limit}`;
		return this.apiService.get(url).pipe(map((res) => {
			res = res || {};
			res.data = res.data || [];
			if (reqType) {
				res.data = _.filter(res.data, (num) => {
					if (
						num.bAssessmentStatus === "AA REJECECTED SSC REQ" &&
						num.cronCompleted &&
						num.cronCompleted.aaAutoReversal &&
						num.cronCompleted.aaAutoReversal.updated
					) {
						num.assessmentStatus = "Auto Reversed";
					} else if (num.bAssessmentStatus.includes("_CR")) {
						num.assessmentStatus =
							num.assessmentStatus || num.bAssessmentStatus;
					} else {
						num.assessmentStatus =
							num.bAssessmentStatus || num.assessmentStatus;
					}
					if (Array.isArray(reqType)) {
						reqType.filter((type) => {
							if (num.bAssessmentStatus.includes("_CR")) {
								if (num.assessmentStatus === type) {
									resArray.push(num);
								}
							} else {
								if (num.bAssessmentStatus === type) {
									resArray.push(num);
								}
							}
						});
					} else {
						return num.bAssessmentStatus.includes("_CR")
							? num.assessmentStatus === reqType
							: num.bAssessmentStatus === reqType;
					}
				});
			} else {
				res.data.map((batch) => {
					if (
						batch.bAssessmentStatus === "AA REJECECTED SSC REQ" &&
						batch.cronCompleted &&
						batch.cronCompleted.aaAutoReversal &&
						batch.cronCompleted.aaAutoReversal.updated
					) {
						batch.assessmentStatus = "Auto Reversed";
					}
					if (category === "CR") {
						batch.assessmentStatus =
							batch.bAssessmentStatus || batch.assessmentStatus;
					} else {
						if (batch.bAssessmentStatus.includes("_CR")) {
							batch.assessmentStatus =
								batch.assessmentStatus ||
								batch.bAssessmentStatus;
						} else {
							batch.assessmentStatus =
								batch.bAssessmentStatus ||
								batch.assessmentStatus;
						}
					}
				});
				// return res
			}
			if (resArray && resArray.length !== 0) {
				res.data = resArray;
				return res;
			} else {
				return res;
			}
		}));
	}

	getFeeBasedBatchByStatusssc(
		filter,
		{
			status,
			bAssessmentStatus,
		}: // bQueries
			{
				status: string[];
				bAssessmentStatus?: string[];
				// bQueries?: string[]
			} = {
				status: [STRING.EMPTY],
				bAssessmentStatus: [STRING.EMPTY],
				// bQueries: [STRING.EMPTY],
			},
		{ pageNo = 1, limit = 10, noPagination = false } = {}
	) {
		const query: any = {
			bAssessmentStatus: status || STRING.EMPTY,
			// bAssessmentStatus: bAssessmentStatus || STRING.EMPTY,
			for: "ssc-assessment",
			sscStatus: bAssessmentStatus || STRING.EMPTY,
			// query: 'jobRoles.assessmentAgencyDetails.status:REJECTED',
			// queries: bQueries,
			// sscStatus: status || STRING.EMPTY,
			// assessment/batch/{batchID}/candidate/{candidateUserName}
		};
		if (!noPagination) {
			query.pageNo = pageNo;
			query.itemsPerPage = limit;
		}
		const schemeTypes = [
			SCHEME_TYPE.FEE_BASED,
			SCHEME_TYPE.STT,
			SCHEME_TYPE.RPL,
			SCHEME_TYPE.SP,
		];
		const url = `${environment.userServiceUrl}/api/batch/v1/scheme/${schemeTypes
			// SCHEME_TYPE.FEE_BASED
			}?${Helpers.serialize(query)}`;
		// }?for=ssc-assessment&sscStatus=assigned&pageNo=${this.pendingBatches.pagination.pageNo}
		// &itemsPerPage=${this.pendingBatches.pagination.limit}`;
		return this.apiService.get(url, filter).pipe(map((res) => {
			res = res || {};
			res.data = res.data || [];
			res.data.map((batch) => {
				batch.assessmentStatus =
					batch.bAssessmentStatus || batch.assessmentStatus;
			});
			return res;
		}));
	}

	getAssessmentOfJobRole(data) {
		const getBatchDetails = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/${data.batchId}/candidate/${data.userName}/jobrole`;
		return this.apiService.get(getBatchDetails, {
			qpCode: data.qpCode,
		});
	}

	getdonerOrCorporateNamelist(name) {
		// http://localhost:3000/v1/batch/donorlist?donorName=abcde
		// http://localhost:3000/api/batch/v1/donorlist?donorName=K
		const url = `${environment.userServiceUrl}/api/batch/v1/donorlist?donorName=${name}`;
		return this.apiService.get(url);
	}

	updateAssesment(data) {
		const getBatchDetails = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/jobrole/attendance`;
		return this.apiService.put(getBatchDetails, {
			attendanceDocument: data.attendanceDocument,
			batchId: data.batchId,
			jobRole: data.jobRole,
			qpCode: data.qpCode,
		});
	}

	getAssemsntDetailsOnBatchId(batchId) {
		const getApi = `${environment.userServiceUrl}/api/batch/v1/get/batch/count/summary?batchId=${batchId}`;

		return this.apiService.get(getApi);
	}

	getSpillOverAssessmentCheck(batch) {
		// localhost:3000/api/user/v1/peoject/spillover/assessment/data?projectId=146&tpId=TP100025
		const URL = `${environment.userServiceUrl}/api/user/v1/project/spillover/assessment/data?projectId=${batch.schemeId}&tpId=${batch.tpId}`;
		return this.apiService.get(URL);
	}

	getCertificateDetails(batchId, userName, qpCode) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate?for=tot&howMany=one&batchId=${batchId}&userName=${userName}&qpCode=${qpCode}`;
		return this.apiService.get(URL);
	}

	// getCertificateDetailsFeebased(batchId, userName, qpCode) {
	// 	// tslint:disable-next-line:max-line-length
	// 	const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate?for=feeBased&howMany=one&batchId=${batchId}&userName=${userName}&qpCode=${qpCode}`;
	// 	return this.apiService.get(URL);
	// }

	getFeeBasedBatchDetails(batchId) {
		const getApi = `${environment.userServiceUrl}/api/batch/v1/batchWithCandidates/${batchId}`;
		return this.apiService.get(getApi);
	}
	getFeeBasedBatchDetailsInTP(batchId) {
		const getApi = `${environment.userServiceUrl}/api/batch/v1/batchWithCandidates/${batchId}?landingPage=Fee Based`;

		return this.apiService.get(getApi);
	}
	getBatchDetails(batchId, sourceData?) {
		const getBatchDetails = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/${batchId}?source=${sourceData}`;
		return this.apiService.get(getBatchDetails).pipe(map((batch) => {
			if (batch) {
				batch.assessmentStatus =
					batch.bAssessmentStatus || batch.assessmentStatus;
				batch.candidates = batch.candidates || [];
				// batch.size = batch.candidates.length || batch.size
			}
			return batch;
		}));
	}
	getJobRolesForCandidate(batchId, userName) {
		const getBatchDetails = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/candidate/${batchId}/${userName}`;
		return this.apiService.get(getBatchDetails);
	}
	getJobroleForbatch(batchId) {
		const getBatchDetails = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/jobrole/`;
		return this.apiService.get(getBatchDetails, {
			batchId: batchId,
		});
	}
	getBatchDetail(batchId, category?) {
		const getBatchDetails = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/id/${batchId}`;
		return this.apiService.get(getBatchDetails).pipe(map((batch) => {
			if (batch) {
				// batch.assessmentStatus = batch.bAssessmentStatus || batch.assessmentStatus
				// if (category === 'CR') {
				// 	batch.assessmentStatus = batch.bAssessmentStatus || batch.assessmentStatus
				// } else {
				// 	if (batch.bAssessmentStatus.includes('_CR')) {
				// 		batch.assessmentStatus = batch.assessmentStatus || batch.bAssessmentStatus
				// 	} else {
				// 		batch.assessmentStatus = batch.bAssessmentStatus || batch.assessmentStatus
				// 	}
				// }
				batch.candidates = batch.candidates || [];
				// batch.size = batch.candidates.length || batch.size
			}
			return batch;
		}));
	}

	getBatchBytcId(batchId, tcId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/batches/${tcId}?batchId=${batchId}`;
		return this.apiService.get(URL);
	}

	getBudgetDetails(batchId, status?) {
		const payload = {
			batchId: batchId,
			status: status,
		};
		const BudgetUrl = `${environment.paymentService}/api/v1/tranche/getbudgetdata`;
		return this.apiService.get(BudgetUrl, payload);
	}

	calculateBudgetDetails(batchId) {
		const payload = {
			batchId: batchId,
		};
		const BudgetUrl = `${environment.paymentService}/api/v1/tranche/budgetcalculation`;
		return this.apiService.post(BudgetUrl, payload);
	}

	getBatchStatus(batchId) {
		const getBatchDetails = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/id/${batchId}`;
		return this.apiService.get(getBatchDetails);
	}
	// getBatches(status = 'Upcoming', pageNo = 1, itemsPerPage = Constants.RECORDSPERPAGE, searchCriterias = {}) {
	// 	console.log(searchCriterias);
	// 	let URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch`;
	// 	if (status === 'Upcoming') {
	// 		URL = `${URL}/${status.toString().toLowerCase()}/`;
	// 		status = 'BATCH CREATED';
	// 	}
	// 	return this.apiService.get(URL, Object.assign(searchCriterias, { status, pageNo, itemsPerPage }));
	// }
	getBatches(
		status = "PENDING",
		pageNo = 1,
		itemsPerPage = Constants.RECORDSPERPAGE,
		searchCriterias = {}
	) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/filter`;
		return this.apiService.post(URL, {
			status: status,
			pageNo: pageNo,
			itemsPerPage: itemsPerPage,
			filterdata: searchCriterias,
		});
	}
	getEnrolledandDropOutCandidates(pageNo = 1, itemsPerPage, batchId: string) {
		let queryParms = {
			pageNo: pageNo,
			size: itemsPerPage,
		};
		queryParms = Object.assign({}, queryParms);
		const URL = `${environment.userServiceUrl}/api/batch/v1/pmkvy/get/batch/all/candidates?batchId=${batchId}`;
		return this.apiService.get(URL, queryParms);
	}

	getMyassessmentAgencyData(data, pageNo, itemsPerPage) {
		const URL = `${environment.userServiceUrl}/api/user/v1/pmkvy/assessmentAgency/location/based`;
		return this.apiService.get(URL, {
			itemsPerPage: itemsPerPage,
			pageNo: pageNo,
			state: data.state || 0,
			district: data.district || 0,
			name: data.name || "",
			userName: data.userName || "",
			type: data.type || "",
			sscName: data.ssc || "",
			biceId: data.biceId || "",
			status: data.status || "",
			keyword: data.keyword || "",
			reassmtBatchId: data.reassmtBatchId || "",
		});
	}

	applyForBatch(batchId, batchType?: string) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/${batchId}/candidate?batchType=${batchType}`;
		return this.apiService.post(URL);
	}
	getCandidateAssesment(data) {
		const getBatchDetails = `${environment.ToTServiceURL}/api/tot-toa/v1/mobile/assessment/candidate`;
		return this.apiService.get(getBatchDetails, {
			candidateUserName: data.userName,
			batchId: data.batchId,
		});
	}
	approveJobRoles(data, remarks = null) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/candidate/jobrole/approval?batchId=${data.batchId}&candidateId=${data.candidateId}&qpCode=${data.qpCode}`;
		return this.apiService.put(URL, {
			review: data.Review,
			jobName: data.jobRoleName,
			certificateURL: data.certificateURL,
			isCertified: data.isCertified,
			status: data.Status,
			type: data.type,
		});
	}
	getComment(batchId, aaId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/pmkvy/get/batch/assessmentAgency/comments?batchId=${batchId}&aaId=${aaId}`;
		return this.apiService.get(URL);
	}

	approveOrRejectBatch({
		review,
		status,
		batchId,
		type,
		candidates,
	}: {
		review?: string;
		status: string;
		batchId: string;
		type?: string;
		candidates?: any;
	}) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/approval/${batchId}`;
		return this.apiService.put(URL, { review, status, type, candidates });
	}
	updateCandidateMarks(data, assement) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/${data.batchId}/candidate/${data.candidateUserName}/jobrole?status=${data.status}&calcOnly=${data.calcOnly}`;
		return this.apiService.put(URL, assement);
	}
	genrateCertificate(data, certificateFor = "tot") {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate?for=${certificateFor}&attendanceStatus=${data.attendanceStatus}`;
		return this.apiService.post(URL, data);
	}
	genrateMarksheet(data, marksheetFor?) {
		marksheetFor = marksheetFor ? marksheetFor : "tot";
		const URL = `${environment.batchServiceUrl}/api/v1/cert/marksheet?for=${marksheetFor}&attendanceStatus=${data.attendanceStatus}`;
		return this.apiService.post(URL, data);
	}

	generateBulkCertificates(data, certificateFor = "tot") {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/bulk?for=${certificateFor}`;
		return this.apiService.post(URL, data);
	}

	generateBulkMarksheetForPmkvy3(data) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/bulk/queueCertificates`;
		return this.apiService.post(URL, data);
	}

	generateBulkMarksheet(data) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/bulk/marksheet`;
		return this.apiService.post(URL, data);
	}

	generateBulkInsuranceCard(actionUrl) {
		const URL = `${environment.pdfGenerate}/api/v1/generatepdfreports/insurancecard?${actionUrl}`;
		return this.apiService.get(URL);

	}

	getCertificateDetailsFeebased(batchId, userName, qpCode) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate?for=feeBased&howMany=one&batchId=${batchId}&userName=${userName}&qpCode=${qpCode}`;
		return this.apiService.get(URL);
	}

	updateCertificateRequest(data) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate/externalRequest?for=tot`;
		return this.apiService.post(URL, data);
	}

	updateAdvanceCertificateRequest(data) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate/advancedCertificate?for=tot`;
		return this.apiService.post(URL, data);
	}

	updateCandidatesAttendance(data) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/mobile/candidate/jobrole/attendance`;
		return this.apiService.put(URL, data);
	}

	selfBulkCertificateDownload(payload) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/bulk/queueNFCertificates`;
		return this.apiService.post(URL, payload);
	}

	generateFeeBasedMarkSheet({
		batchId,
		userName,
		qpCode,
		type,
		grade,
		marksheetURL,
		attendanceStatus,
		nsdcRegistrationNumber,
		marksheetType,
	}) {
		const MARKSHEET_FOR_MAPPING = {
			feeBased: "tot",
			pmkvy: SCHEME_TYPE.PMKVY,
		};
		marksheetType =
			MARKSHEET_FOR_MAPPING[marksheetType] || SCHEME_TYPE.FEE_BASED;
		return this.genrateMarksheet(
			{
				batchId,
				userName,
				qpCode,
				type,
				grade,
				marksheetURL,
				marksheetType,
				attendanceStatus,
				nsdcRegistrationNumber,
			},
			marksheetType
		);
	}
	generateFeeBasedCertificate({
		grade,
		batchId,
		qpCode,
		userName,
		attendanceStatus,
		nsdcRegistrationNumber,
		certificateFor,
	}) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate?for=${certificateFor}&&attendanceStatus=${attendanceStatus}`;
		// const URL = `${environment.batchServiceUrl}/api/v1/ cert/certificate ? for  = feeBased && attendanceStatus = ${attendanceStatus}`;
		return this.apiService.post(URL, {
			grade,
			batchId,
			qpCode,
			userName,
			attendanceStatus,
			nsdcRegistrationNumber,
			certificateFor,
		});
	}
	getBatchesForCandidate(
		filter: string,
		pageNo = 0,
		itemsPerPage = Constants.RECORDSPERPAGE
	) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/candidate/`;
		return this.apiService.get(URL, {
			status: filter.toUpperCase(),
			pageNo,
			itemsPerPage,
		});
	}
	totSearch(paramData, data?: any, pageNo?, size?) {
		pageNo = pageNo ? pageNo : 1;
		size = size ? size : 10;
		const listType = paramData.listType ? paramData.listType : "";
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/candidate/list/search?keyword=${paramData.keyword}&status=${paramData.status}&batchId=${paramData.batchId}&sortBy=${paramData.sortBy}&sortOrder=-1&pageNo=${pageNo}&size=${size}&candidatesList=${listType}`;
		return this.apiService.post(URL, {
			type: data && data.type ? data.type : "",
			district: data && data.district ? data.district : "",
			state: data && data.state ? data.state : "",
			batchStartDate:
				data && data.batchStartDate ? data.batchStartDate : "",
			batchEndDate: data && data.batchEndDate ? data.batchEndDate : "",
			payment: data && data.payment ? data.payment : "",
			insurance: data && data.insurance ? data.insurance : "",
			kits: data && data.kits ? data.kits : "",
			employment: data && data.employment ? data.employment : "",
			// searchText: data && data.searchText ? data.searchText : "",
		});
	}
	// Auto dropped
	autoDropped(batchId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/${batchId}/autodropped/candidate/list`;
		return this.apiService.get(URL);
	}

	abeasCheck(schemeId, tcId, qpcode, schemetype) {
		const URL = `${environment.userServiceUrl}/api/user/v1/adhar/required/project/scheme/level?projectId=${schemeId}&tcId=${tcId}&qpCode=${qpcode}&schemeType=${schemetype}`;
		return this.apiService.get(URL);
	}
	trainingCalendarSearch(data?: any, pageNo?: any, size?: any) {
		const urlData = {
			qp: data && data.qp ? data.qp : "",
			sector: data && data.sector ? data.sector : "",
			subsector: data && data.subsector ? data.subsector : "",
			state: data && data.state ? data.state : 0,
			batchType: data && data.batchtype ? data.batchtype : "",
			status: data && data.status ? data.status : "",
			bStartDate: data && data.startDate ? data.startDate : null,
			bEndDate: data && data.endDate ? data.endDate : null,
		};
		const URL = `${environment.ToTServiceURL}
						/api/tot-toa/v2/list/all/${data.displayTab}
						?keyword=${data.keyword}&pageNo=${pageNo}&size=${size}&sortOrder=${data.order}&sortBy=${data.sortBy}`;
		return this.apiService.post(URL, urlData);
	}
	getSSCList(data?: any) {
		const URL = `${environment.userServiceUrl}/api/user/v1/ssc/module/TOTA?sectorId=${data.sectorId}&keyword=${data.keyword}`;
		return this.apiService.get(URL);
	}

	// Get SSC details based on id
	getAllSccDetailsOnids(data, role, pageNo?, limit?) {
		const query = {
			ids: data,
		};
		const getParticipated = `${environment.userServiceUrl}/api/user/v1/getUserBasedOnRoleAndIds?role=${role}&pageNo=${pageNo}&limit=${limit}`;
		return this.apiService.post(getParticipated, query);
	}

	getpmkvyBatchList(data?: any) {
		if (data.state === "") {
			data.state = 0;
		}
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v2/pmkvybatch/list/all/public`;
		return this.apiService.post(URL, data);
	}

	updatePmkvyEnrollCandidiates(batchId, tpId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/pmkvy/enroll/candidates?tpId=${tpId}&batchId=${batchId}`;
		return this.apiService.patch(URL);
	}

	/* Fee Based APIs */
	createBatchFeebased(data: any) {
		const createBatchUrl =
			environment.userServiceUrl + "/api/batch/v1/create";
		return this.apiService.post(createBatchUrl, data);
	}

	updateBatchFeebased(scheme, batchId, data: any) {
		const createBatchUrl =
			environment.userServiceUrl + `/api/batch/v1/scheme/${scheme}/${batchId}`;
		return this.apiService.patch(createBatchUrl, data);
	}

	raiseBatchCancellationRequest(batchId: any) {
		const createBatchUrl =
			environment.userServiceUrl + `/api/batch/v1/cancelbatch/${batchId}`;
		return this.apiService.get(createBatchUrl);
	}

	updateBatch(data: any) {
		const editBatchUrl =
			environment.userServiceUrl + "/api/batch/v1/update";
		return this.apiService.put(editBatchUrl, data);
	}
	previewCandidatesFeebased(batchID: any, fileWithData: any) {
		return this.previewCandidatesFeebasedV2(batchID, fileWithData);
	}

	previewCandidatesFeebasedV2(batchID: any, fileWithData: any) {
		const previewUrl = `${environment.userServiceUrl}/v1/candidates/batch/${batchID}/reg/preview_candidatesV2`;
		return this.apiService.post(previewUrl, fileWithData);
	}

	previewEnrollAndAttendanceCandidates(batchID: any, fileWithData: any) {
		const previewUrl = `${environment.userServiceUrl}/v1/candidates/batch/${batchID}/reg/preview_candidatesV2?stage=enrollWithAttendance`;
		return this.apiService.post(previewUrl, fileWithData);
	}

	enrollCandidateFeebasedBatch(batchID: any, fileWithData: any) {
		// const enrollApiUrl = `${environment.userServiceUrl}/v1/candidates/batch/${
		// 	batchID
		// 	}/enrollCandidates`;
		// return this.apiService.put(enrollApiUrl, fileWithData);
		return this.enrollCandidateFeebasedBatcV2(batchID, fileWithData);
	}

	enrollCandidateFeebasedBatcV2(batchID: any, fileWithData: any) {
		const enrollApiUrl = `${environment.userServiceUrl}/v1/candidates/batch/${batchID}/enrollCandidatesV2`;
		return this.apiService.put(enrollApiUrl, fileWithData);
	}

	enrollCandidateWithAttendance(batchID: any, fileWithData: any) {
		const enrollApiUrl = `${environment.userServiceUrl}/v1/candidates/batch/${batchID}/enrollCandidatesV2?stage=enrollAndAttendance`;
		return this.apiService.put(enrollApiUrl, fileWithData);
	}
	submitFeebasedStage(batchID: string, stage: string, fileWithData: any) {
		// const stageApi = `${environment.userServiceUrl}/v1/candidates/batch/${
		// 	batchID
		// 	}/training_details?stage=${stage}`;

		// return this.apiService.post(stageApi, fileWithData);
		return this.submitFeebasedStageV2(batchID, stage, fileWithData);
	}

	submitFeebasedStageV2(batchID: string, stage: string, fileWithData: any) {
		const stageApi = `${environment.userServiceUrl}/v1/candidates/batch/${batchID}/training_detailsV2?stage=${stage}`;

		return this.apiService.post(stageApi, fileWithData);
	}

	feebasedAssignAssessment(
		stage: string,
		batchID: string,
		data: any
	): Observable<any> {
		const assessmentBatchUrl = `${environment.userServiceUrl}/api/batch/v1/${stage}/${batchID}`;
		return this.apiService.put(assessmentBatchUrl, data);
	}

	rejectBatch(batchId, payLoad) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/scheme/feeBased/${batchId}`;
		return this.apiService.patch(URL, payLoad);
	}

	submitBatchToSsDM(batchId, payLoad) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/submit/to/ssdm/pmu/${batchId}`;
		return this.apiService.put(URL, payLoad);
	}
	feebasedBatchCandidateCertificateUpload(
		candidateID: string,
		BatchID: string,
		data: any
	) {
		const certificateApi = `${environment.userServiceUrl}/v1/candidates/${candidateID}/batch/${BatchID}/certificationDetails`;

		return this.apiService.put(certificateApi, data);
	}

	feebasedBatchCandidatePlacementeUpload(
		candidateID: string,
		BatchID: string,
		data: any
	) {
		const placementApi = `${environment.userServiceUrl}/v1/candidates/${candidateID}/batch/${BatchID}/placementDetails`;

		return this.apiService.put(placementApi, data);
	}
	// getApplicantBatches() {
	// 	const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/applicant/mybatches?itemsPerPage=100&pageNo=1`;
	// 	return this.apiService.get(URL);
	// };
	getApplicantBatches(
		pageNo = 1,
		itemsPerPage = Constants.RECORDSPERPAGE,
		searchCriterias = {}
	) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/applicant/mybatches`;
		return this.apiService.post(URL, {
			pageNo: pageNo,
			itemsPerPage: itemsPerPage,
			filterdata: searchCriterias,
		});
	}
	postFeedBack(data, BatchID) {
		const feedBackApi = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/feedback?batchId=${BatchID}`;

		return this.apiService.put(feedBackApi, data);
	}

	postFeedBackForCertificate(data, certReqId) {
		const feedBackApi = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/jobrole/feedback?certReqId=${certReqId}`;

		return this.apiService.put(feedBackApi, data);
	}
	// getPublishedBatches(pageNo = 1, itemsPerPage = Constants.RECORDSPERPAGE, searchCriterias = {}) {
	// 	const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ssc/published`;

	// 	return this.apiService.get(URL,
	// 		Object.assign(searchCriterias,
	// 			{ pageNo, itemsPerPage }));
	// }
	getPublishedBatches(
		pageNo = 1,
		itemsPerPage = Constants.RECORDSPERPAGE,
		searchCriterias = {}
	) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ssc/published`;
		console.log(URL);

		return this.apiService.post(URL, {
			status: status,
			pageNo: pageNo,
			itemsPerPage: itemsPerPage,
			filterdata: searchCriterias,
		});
	}

	updateApplicantPaymentDetails(batchId, data) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/applicant/payment?batchId=${batchId}`;
		return this.apiService.put(URL, data);
	}

	getCertificateRequest(pageNo, perPage, displayTab, data?) {
		console.log(data);
		const userType = data && data.userType ? data.userType : "";
		const originationCategory =
			data && data.originationCategory ? data.originationCategory : "";
		const country =
			data && data.country && data.country.countryId !== "IN"
				? data.country.countryId
				: "";
		const batchType = data && data.batchType ? data.batchType : "";
		const qpCode = data && data.jobRole ? data.jobRole : "";
		const state = data && data.state ? data.state : "";
		const applicantId =
			data && data.applicantId ? "userName:" + data.applicantId : "";
		const typeOfCertification =
			data && data.typeOfCertification ? data.typeOfCertification : "";
		const applicantName =
			data && data.applicantName
				? (applicantId !== "" ? ",name:" : "name:") + data.applicantName
				: "";

		const url = `${environment.userServiceUrl}/api/user/v1/trainer/Certification?pageNo=${pageNo}&limit=${perPage}&displaytab=${displayTab}&filter=countryCode:${country},originationCategory:${originationCategory},userType:${userType}
			,batchType:${batchType},qpCode:${qpCode},state:${state},typeOfCertification:${typeOfCertification}&search=${applicantId}${applicantName}`;
		// }/api/user/v1/trainer/Certification?displaytab=Request&fields=userName,name,jobName,jobRoleId,certificateDetails`;

		return this.apiService.get(url);
	}
	getApprovedCertificateRequest(pageNo, perPage) {
		const url = `${environment.userServiceUrl
			// tslint:disable-next-line:max-line-length
			}/api/user/v1/trainer/Certification?pageNo=${pageNo}&limit=${perPage}&displaytab=Approved`;

		return this.apiService.get(url);
	}
	updateCeremonyforBatch(batchId, data) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/update/ceremony/photo/upload/detail?batchId=${batchId}`;
		return this.apiService.patch(URL, data);
	}
	setCertificateValue(data) {
		this.certificate = data;
	}
	getCertificateValue() {
		return this.certificate;
	}
	approveRequest(data) {
		const url = `${environment.userServiceUrl}
			/api/user/v1/trainer/Certification?applicantID=${data.applicantID}&jobRoleID=${data.jobRoleID
			}&status=${data.status}
			&comment=${data.comment ? data.comment : ""}&documentURL=${data.documentURL ? data.documentURL : ""
			}`;
		return this.apiService.put(url);
	}

	approveRequestforSSC(data) {
		const url = `${environment.userServiceUrl}/api/user/v1/trainer/Certification`;
		return this.apiService.put(url, data);
	}
	reScheduleApprove(batchId, action?, data?) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ssc/updateRequests/${batchId}/rescheduled/${action}`;
		// const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/status/${batchId}/rescheduled${action}`;
		return this.apiService.patch(URL, data);
	}

	cancelRechedule(batchId, action?, data?) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ssc/updateRequests/${batchId}/rescheduled/${action}`;
		return this.apiService.patch(URL, data);
	}
	approveCancel(batchId, action?, data?) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ssc/updateRequests/${batchId}/cancelled/${action}`;
		// const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/status/${batchId}/cancelled`;
		return this.apiService.patch(URL, data);
	}

	rejectCancelReq(batchId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ssc/updateRequests/${batchId}/cancelled`;
		// const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/ssc/updateRequests/${batchId}/cancelled`;
		return this.apiService.patch(URL);
	}

	cancelBatchIfNoCandidate(batchId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/status/${batchId}/cancelled?dropAll=YES`;
		return this.apiService.patch(URL);
	}

	candidateDetails(candidateId: any) {
		const candidateApiUrl = `${environment.userServiceUrl}/v1/candidates/${candidateId}`;
		return this.apiService.get(candidateApiUrl);
	}
	getPendingBatches(filters) {
		if (!filters["itemsPerPage"]) {
			filters["itemsPerPage"] = Constants.RECORDSPERPAGE;
		}
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/filter`;
		return this.apiService.post(URL, filters).pipe(map((res) => {
			res.batches = res.batches || [];
			res.batches.map((batch) => {
				batch.candidates = batch.candidates || [];
				// batch.size = batch.candidates.length || batch.size
			});
			return res;
		}));
	}
	excelDownloadForAJobRole(batchId, qpCode) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/applicantsAttendance/Download`;
		return this.apiService.getBlob(URL, "application/vnd.ms-excel", {
			batchId: batchId,
			qpCode: qpCode,
		});
	}
	excelUploadForAJobRole(batchId, qpCode, data) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/applicantsAttendanceUpload?batchId=${batchId}&qpCode=${qpCode}`;
		return this.apiService.post(URL, data);
	}

	getAttendaceOfBarch(batchId, qpCode) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1//batch/get/applicants/AttendanceDetails?batchId=${batchId}&qpCode=${qpCode}`;
		return this.apiService.get(URL);
	}

	getAttendanceUploadedDocs(batchId, qpCode) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/applicants/get/documentsURL?batchId=${batchId}&qpCode=${qpCode}`;
		return this.apiService.get(URL);
	}

	submitAttendanceToSsc(batchId, qpCode, data) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/applicants/attendanceSubmitToSSC?batchId=${batchId}&qpCode=${qpCode}`;
		return this.apiService.put(URL, data);
	}

	submitAttendanceAdditionalDocsUrlsToSSc(batchId, qpCode, data) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/applicants/update/attendanceDocument?batchId=${batchId}&qpCode=${qpCode}`;
		return this.apiService.put(URL, data);
	}

	updateMarksheetOrCertificateStatus(data, fileUrl) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/
		${data.batchId}/candidate/${data.userName}/jobrole?qpCode=${data.qpCode}`;
		return this.apiService.put(URL, fileUrl);
	}

	// TEMP
	// DONT REMOVE THIS

	feebasedAssignWorkAround(batchID: string) {
		const assessmentBatchUrl = `${environment.ToTServiceURL}/api/tot-toa/v1/mobile/assessment?batchId=${batchID}`;
		const body = {
			batchId: batchID,
		};
		return this.apiService.put(assessmentBatchUrl, body);
	}

	getBatchForScheme({
		schemeType,
		finalStartDate,
		finalEndDate,
		stageName,
		filterbatchId,
		pageNo,
		perPageNo,
		trianingCentreId,
	}) {
		const url = `${environment.userServiceUrl}/api/batch/v1/scheme/
		${schemeType}?batchStartDate=${finalStartDate}&batchEndDate=${finalEndDate}&stage=${stageName}&batchId=${filterbatchId}&pageNo=${pageNo}&itemsPerPage=${perPageNo}&tcId=${trianingCentreId}`;
		return this.apiService.get(url);
	}

	getBatchesForCMA(schemeType, pageNo, perPageNo, schemeId, query) {
		console.log(query);
		const url = `${environment.userServiceUrl}/api/batch/v1/scheme/
		${schemeType}?pageNo=${pageNo}&itemsPerPage=${perPageNo}&schemeReferenceId=${schemeId}
		&batchId=${query.batchId ? query.batchId : ""}
		&tcId=${query.trianingCentreId ? query.trianingCentreId : ""}
		&tpId=${query.tpId ? query.tpId : ""}`;
		return this.apiService.get(url);
	}

	getBatchForPmkvyScheme(
		schemeId,
		tcId,
		pageNo,
		perPageNo,
		status?,
		filterStartDate?,
		filterEndDate?,
		stageName?,
		filterbatchId?,
		batchType?
	) {
		const objbatch = {
			tcId: tcId,
			pageNo: pageNo,
			itemsPerPage: perPageNo,
			status: status,
			batchStartDate: filterStartDate,
			batchEndDate: filterEndDate,
			stage: stageName,
			batchId: filterbatchId,
			batchType: batchType,
		};

		const filters = Object.keys(objbatch)
			.filter((filter) => objbatch[filter])
			.reduce((finalFilter, filterKey) => {
				finalFilter[filterKey] = objbatch[filterKey];
				return finalFilter;
			}, {});
		console.log(Helpers.serialize(filters), "helpers serialize");

		const url = `${environment.userServiceUrl
			}/api/batch/v1/list/batches/${schemeId}
		?${Helpers.serialize(filters)}`;
		return this.apiService.get(url);
	}

	/**
	 * Returns batch end date
	 * @param trainingHoursPerDay
	 * @param batchStartDate
	 * @param Noofdaysfromschemerule
	 */
	getDatePmkvyBatch(data, isRpl?: string) {
		const url = `${environment.userServiceUrl}/api/batch/v1/get/batch/end/date?rpl=${isRpl}`;
		return this.apiService.post(url, data);
	}

	/**
	 * Updates batch session - PMKVY
	 * @param batchStartDate
	 * @param batchEndDate
	 */
	getBatchDurationPmkvyBatch(data) {
		const url = `${environment.userServiceUrl}/api/batch/v1/get/batch/total/work/hour`;
		return this.apiService.post(url, data);
	}

	/**
	 * Updates batch session - PMKVY
	 * @param batchId
	 * @param sessions
	 */
	addUpdateBatchSession(batchId: number, data: any) {
		const url = `${environment.userServiceUrl}/api/batch/v1/update/sessions/${batchId}`;
		return this.apiService.patch(url, data);
	}

	getBatchSession(batchId: number, date: string) {
		const url = `${environment.userServiceUrl}/api/batch/v1/get/sessions/${batchId}?date=${date}`;
		return this.apiService.get(url);
	}

	getBatchSessionV1(batchId: number) {
		const url = `${environment.userServiceUrl}/api/batch/v1/get/all/sessions/${batchId}`;
		return this.apiService.get(url);
	}

	getValidationBatchSession(batchId: number, ignoreServerResponseError?) {
		let url = `${environment.userServiceUrl}/api/batch/v1/get/validation/data/session/${batchId}`;
		if (ignoreServerResponseError) {
			url = url.concat(
				`?ignoreServerResponseError=${ignoreServerResponseError}`
			);
		}
		return this.apiService.get(url);
	}

	getCandidateByTp(
		pageNo,
		limit,
		userId,
		filter?,
		isSchemeMoma?,
		batchId?,
		ischildbatchmouha?
	) {
		const queryParams = {};
		queryParams["limit"] = limit;
		queryParams["pageNo"] = pageNo;
		if (userId) {
			queryParams["tpId"] = userId;
		}
		if (filter.keyword) {
			queryParams["candidateId"] = filter.keyword;
		}
		if (filter && filter.canId) {
			queryParams["candidateId"] = filter.canId;
		}

		if (isSchemeMoma) {
			queryParams["schemeType"] = isSchemeMoma;
		}
		if (batchId) {
			queryParams["batchId"] = batchId;
		}
		if (ischildbatchmouha) {
			queryParams["ischildbatchmouha"] = ischildbatchmouha;
		}
		const apiUrl = `${environment.userServiceUrl}/v1/candidates/get/detail`;
		return this.apiService.get(apiUrl, queryParams);
	}
	getCandidateByTpWithBL(
		pageNo,
		limit,
		userId,
		tcId,
		createdBy?,
		filter?,
		isSchemeMoma?,
		batchId?,
		ischildbatchmouha?
	) {
		const queryParams = {};
		queryParams["limit"] = limit;
		queryParams["pageNo"] = pageNo;
		if (userId) {
			queryParams["tpId"] = userId;
		}
		if (tcId) {
			queryParams["tcId"] = tcId;
		}
		if (filter && filter.canId) {
			queryParams["candidateId"] = filter.canId;
		}
		if (filter && filter.state) {
			queryParams["state"] = filter.state;
		}
		if (filter && filter.district) {
			queryParams["district"] = filter.district;
		}
		if (createdBy) {
			queryParams["createdBy"] = createdBy;
		}
		if (isSchemeMoma) {
			queryParams["schemeType"] = isSchemeMoma;
		}
		if (batchId) {
			queryParams["batchId"] = batchId;
		}
		if (ischildbatchmouha) {
			queryParams["ischildbatchmouha"] = ischildbatchmouha;
		}
		// if (filter && filter.createBy) {
		// 	queryParams['createBy'] = filter.createBy
		// }

		const apiUrl = `${environment.userServiceUrl}/v1/candidates/get/detail`;
		return this.apiService.get(apiUrl, queryParams);
	}

	getCandidateForPmkvy4(pageNo, limit, filter?) {
		let apiUrl ='';
		const payload = {};
		payload["limit"] = limit || 1;
		payload["pageNo"] = pageNo || 10;
		// payload["srcofregister"] = "all";
		// payload["interestedInPMKVY"] = true;
		if (filter && filter.canId) {
			payload["candidateId"] = filter.canId;
		}
		if (filter && filter.state) {
			payload["state"] = filter.state;
		}
		if (filter && filter.district) {
			payload["district"] = filter.district;
		}
		if (filter && filter.phone) {
			payload["phone"] = filter.phone;
		}
		if (filter && filter.SidInterestBatchId) {
			payload["SidInterestBatchId"] = filter.SidInterestBatchId;
			payload["SidInterestStatus"] = filter.SidInterestStatus;
		}
		if (filter && filter.VishwakarmaSubSchemeTypeKey) {
			payload[filter.VishwakarmaSubSchemeTypeKey] = 'YES';
		}
		if(filter && filter.pmkvyPoolCandidates){
			apiUrl = `${environment.userServiceUrl}/v1/candidates/pmkvy/candidates/list?limit=${limit || 1}&pageNo=${pageNo || 10}&pmkvyPoolCandidates=${filter.pmkvyPoolCandidates}`;
		}else{
			apiUrl = `${environment.userServiceUrl}/v1/candidates/pmkvy/candidates/list?limit=${limit || 1}&pageNo=${pageNo || 10}`;
		}

		return this.apiService.post(apiUrl, payload);
	}


	enrollCandidate(candidateArray, batchId, userId, canEnrollMore, sidInterestEnrollment?) {
		console.log("BEFORE HITTING CAN ENROLLL MORE CAN", canEnrollMore);
		let url = '';
		if (sidInterestEnrollment && sidInterestEnrollment == 'YES') {
			url = `${environment.userServiceUrl}/api/batch/v1/enroll/candidates?tpId=${userId}&batchId=${batchId}&enrollCondition=${canEnrollMore}&sidInterestEnrollment=${sidInterestEnrollment}`;
		} else {
			url = `${environment.userServiceUrl}/api/batch/v1/enroll/candidates?tpId=${userId}&batchId=${batchId}&enrollCondition=${canEnrollMore}`;
		}
		return this.apiService.patch(url, candidateArray);
	}

	getDraftedCandidate(batchId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/draft/candidates?batchId=${batchId}`;
		return this.apiService.get(URL);
	}
	getCandidateIdsFromBatch(pageNo, itemPerpage, userId, batchId, status) {
		const queryParams = {};
		queryParams["limit"] = itemPerpage;
		queryParams["pageNo"] = pageNo;
		const apiUrl = `${environment.userServiceUrl}/api/batch/v1/get/candidate/ids?tpId=${userId}&batchId=${batchId}&status=${status}`;
		return this.apiService.get(apiUrl, queryParams);
	}

	getEnrolledCandidate(pageNo, itemPerPage, batchId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/pmkvy/get/batch/enrol/candidates/with/pagination?batchId=${batchId}&pageNo=${pageNo}&itemsPerPage=${itemPerPage}`;
		return this.apiService.get(URL);
	}

	dropCandidateFromBatch(batchId, userId, payLoad, sidInterestEnrollmentCancel?) {
		let url;
		if (sidInterestEnrollmentCancel) {
			url = `${environment.userServiceUrl}/api/batch/v1/drop/candidates?tpId=${userId}&batchId=${batchId}&sidInterestCandidateDropped=${sidInterestEnrollmentCancel}`;
		} else {
			url = `${environment.userServiceUrl}/api/batch/v1/drop/candidates?tpId=${userId}&batchId=${batchId}`;
		}
		return this.apiService.patch(url, payLoad);
	}

	removeCandidateFromBatch(payLoad) {
		const apiUrl = `${environment.userServiceUrl}/v1/candidates/remove/candidate`;
		return this.apiService.post(apiUrl, payLoad);
	}

	getCandidateFromBatch(pageNo, limit, canIdsArray) {
		const apiUrl = `${environment.userServiceUrl}/v1/candidates/get/details/cadidateids?limit=${limit}&pageNo=${pageNo}`;
		return this.apiService.post(apiUrl, canIdsArray);
	}

	updateCandidateAttendance(batchId: number, candidates: any[]) {
		const URL = `${environment.userServiceUrl}/v1/candidates/patch/batch/trainer/detail?batchId=${batchId}`;
		return this.apiService.patch(URL, { candidates: candidates });
	}

	trainingRequestsUpdated(data) {
		const Url = `${environment.userServiceUrl}/api/user/v1/trainers/trainingRequests/all`;
		console.log(Url);
		return this.apiService.post(Url, data);
	}

	assessorRequestsUpdated(data) {
		const Url = `${environment.userServiceUrl}/api/user/v1/assessors/trainingRequests/all`;
		console.log(Url);
		return this.apiService.post(Url, data);
	}

	downloadExcelFileOfCandidate(batchID: any, dropoutKeyToSend?) {
		const dropout = false;
		let apiUrl = `${environment.userServiceUrl}/v1/candidates/batch/${batchID}/entrollCandidates/download`;
		if (dropoutKeyToSend) {
			apiUrl = apiUrl.concat(`?dropout=${dropout}`);
		}
		const type = "application/vnd.ms-excel";
		return this.apiService.getBlobOnArguments(apiUrl, {}, type);
	}

	getBatchesONjobRole(data, pageNo, limit) {
		const Url = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/candidate/get/batchlist?pageNo=${pageNo}&size=${limit}`;
		console.log(Url);
		return this.apiService.post(Url, data);
	}

	getBatchesONCandidateId(query) {
		// tslint:disable-next-line:max-line-length
		const Url = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/candidate/get/candidate/list?pageNo=${query.pageNo}&size=${query.itemsPerPage}`;
		console.log(Url);
		return this.apiService.get(Url);
	}

	getBatchesForTrancheByCandidateID(query) {
		const Url = `${environment.paymentService}/api/v1/tranche/getbatchesbycandidate?pageNum=${query.pageNo}&itemsPerPage=${query.itemsPerPage}`;
		console.log(Url);
		return this.apiService.get(Url);
	}
	mapType(
		type: string,
		schemeType: string,
		forcedSchemeName?: string,
		isCSSMBatch?: any
	) {
		return this.totBatchService.mapType(
			type,
			schemeType,
			forcedSchemeName,
			isCSSMBatch
		);
	}

	getBatchStatusforPMU(batch) {
		console.log(batch.bAssessmentStatus);
		if (batch && batch.bAssessmentStatus) {
			return PMU_STATUSES[batch.bAssessmentStatus];
		} else {
			return "Batch Created";
		}
	}

	getBatchesByProjectId(tpId, projectFields, projectId?, monthNumber?) {
		const Url = `${environment.userServiceUrl}/api/batch/v1/get/batches/tp/${tpId}?projectFields=${projectFields}&projectId=${projectId}&monthNumber=${monthNumber}`;
		console.log(Url);
		return this.apiService.get(Url);
	}
	assessmentDateCR(
		batchId: string,
		assessmentDateCR: IAssessmentDateCR,
		qpCode: string
	) {
		qpCode = encodeURIComponent(qpCode);
		const url = `${environment.userServiceUrl}/api/batch/v1/assessmentDateCR/${batchId}?qpCode=${qpCode}`;
		return this.apiService.post(url, assessmentDateCR);
	}

	acceptAssessmentDateCr(
		batchId: string,
		assessmentDateCRPatch?: IAssessmentDateCRPatch,
		qpCode?: string
	) {
		const url = `${environment.userServiceUrl}/api/batch/v1/acceptAssessmentDateCR/${batchId}?showServerSuccessMessage=true&qpCode=${qpCode}`;
		return this.apiService.post(url, assessmentDateCRPatch);
	}

	getSubSchemeType(batch) {
		if (batch.schemeType && batch.schemeType === "RPL") {
			// if (batch.subSchemeType) {
			// 	return ('TYPE ' + batch.subSchemeType)
			// } else {
			// 	return Helpers.getValueFromObject(batch, 'subSchemeName', '')
			// }
			switch (batch.subSchemeType) {
				case "1":
					return "TYPE 1";
				case "2":
					return "TYPE 2";
				case "3":
					return "TYPE 3";
				case "4":
					return "TYPE 4";
				case "5":
					return "TYPE 5";
				case "6":
					return "GKRA RPL 1";
				default:
					return Helpers.getValueFromObject(
						batch,
						"subSchemeName",
						""
					);
			}
		} else {
			return batch.subSchemeName ? batch.subSchemeName : "";
		}
	}

	getLanguages(batch) {
		let language = "";
		if (batch && batch.preferredAssessmentLanguage) {
			for (let i = 0; i < batch.preferredAssessmentLanguage.length; i++) {
				if (i + 1 === batch.preferredAssessmentLanguage.length) {
					language += batch.preferredAssessmentLanguage[i] + "";
				} else {
					language += batch.preferredAssessmentLanguage[i] + ", ";
				}
			}
			return language;
		} else {
			return "N/A";
		}
	}

	getEnrolledCandidatesCount(batch) {
		const totalCount =
			batch && batch.candidates && batch.candidates.length
				? batch.candidates.length
				: 0;
		let enrollCount = 0;
		if (batch && batch.candidates) {
			batch.candidates.filter((res) => {
				if (res.status !== "DROPPED") {
					enrollCount++;
				}
			});
		}
		return enrollCount;
	}

	getFormattedBatchData(batch, type) {
		if (
			type === "aaName" &&
			batch &&
			batch.jobRoles[0] &&
			batch.jobRoles[0].assessmentAgencyDetails &&
			batch.jobRoles[0].assessmentAgencyDetails.name
		) {
			if (
				batch &&
				batch.jobRoles[0] &&
				batch.jobRoles[0].assessmentAgencyDetails &&
				batch.jobRoles[0].assessmentAgencyDetails.userName
			) {
				return (
					batch.jobRoles[0].assessmentAgencyDetails.name +
					" (" +
					batch.jobRoles[0].assessmentAgencyDetails.userName +
					")"
				);
			} else {
				return batch.jobRoles[0].assessmentAgencyDetails.name;
			}
		} else if (
			type === "arName" &&
			batch.jobRoles[0] &&
			batch.jobRoles[0].assessmentAgencyDetails
			// &&
			// batch.jobRoles[0].assessmentAgencyDetails.assessorDetails &&
			// batch.jobRoles[0].assessmentAgencyDetails.assessorDetails.name
		) {
			if (batch.jobRoles[0].assessmentAgencyDetails.assessorDetails) {
				return (
					batch.jobRoles[0].assessmentAgencyDetails.assessorDetails
						.name +
					" (" +
					batch.jobRoles[0].assessmentAgencyDetails.assessorDetails
						.userName +
					")"
				);
			} else if (
				batch.jobRoles[0].assessmentAgencyDetails.proctorDetails
			) {
				return (
					batch.jobRoles[0].assessmentAgencyDetails.proctorDetails
						.name +
					" (" +
					batch.jobRoles[0].assessmentAgencyDetails.proctorDetails
						.userName +
					")"
				);
			} else {
				return "Not yet Assigned";
			}
		} else {
			return "Not yet Assigned";
		}
	}

	getRplBatchesCount(tcId: string) {
		const url = `${environment.userServiceUrl}/api/batch/v1/get/rpl/batch/count?tcId=${tcId}`;
		return this.apiService.get(url);
	}
	assessorCR(batchId: string, assessorCR: IAssessorCR) {
		const url = `${environment.userServiceUrl}/api/batch/v1/assessorCR/${batchId}?showServerSuccessMessage=true`;
		return this.apiService.post(url, assessorCR);
	}

	acceptAssessorCR(batchId: string, assessorCR: IAssessorCRPatch) {
		// const query = {
		// 	qpCode: qpCode,
		// };
		const url = `${environment.userServiceUrl}/api/batch/v1/acceptAssessorCR/${batchId}?showServerSuccessMessage=true`;
		return this.apiService.post(url, assessorCR);
	}

	assessmentAgencyCR(
		batchId: string,
		assessmentAgencyCR: IAssessmentAgencyCR
	) {
		const url = `${environment.userServiceUrl}/api/batch/v1/assessmentAgencyCR/${batchId}?showServerSuccessMessage=false`;
		return this.apiService.post(url, assessmentAgencyCR);
	}

	acceptAssessmentAgencyCR(
		batchId: string,
		assessmentAgencyCR: IAssessmentAgencyCRPatch
	) {
		// const query = {
		// 	qpCode: qpCode,
		// };
		const url = `${environment.userServiceUrl}/api/batch/v1/acceptAssessmentAgencyCR/${batchId}?showServerSuccessMessage=true`;
		return this.apiService.post(url, assessmentAgencyCR);
	}

	batchEndDateCR(batchId: string, batchEndDateCR: IBatchEndDateCR) {
		const url = `${environment.userServiceUrl}/api/batch/v1/batchEndDateCR/${batchId}?showServerSuccessMessage=true`;
		return this.apiService.post(url, batchEndDateCR);
	}

	acceptBatchEndDateCR(
		batchId: string,
		assessmentAgencyCR: IBatchEndDateCRPatch
	) {
		// const query = {
		// 	qpCode: qpCode,
		// };
		const url = `${environment.userServiceUrl}/api/batch/v1/acceptBatchEndDateCR/${batchId}?showServerSuccessMessage=true`;
		return this.apiService.post(url, assessmentAgencyCR);
	}

	batchTimingsCR(batchId: string, batchTimingsCR: IBatchEndDateCR) {
		const url = `${environment.userServiceUrl}/api/batch/v1/batchTimingsCR/${batchId}?showServerSuccessMessage=true`;
		return this.apiService.post(url, batchTimingsCR);
	}

	acceptBatchTimingsCR(
		batchId: string,
		assessmentAgencyCR: IBatchTimingsCRPatch
	) {
		// const query = {
		// 	qpCode: qpCode,
		// };
		const url = `${environment.userServiceUrl}/api/batch/v1/acceptBatchTimingsCR/${batchId}?showServerSuccessMessage=true`;
		return this.apiService.post(url, assessmentAgencyCR);
	}

	trainerDetailsCR(batchId: string, trainerDetailsCR: IBatchEndDateCR) {
		const url = `${environment.userServiceUrl}/api/batch/v1/trainerDetailsCR/${batchId}?showServerSuccessMessage=true`;
		return this.apiService.post(url, trainerDetailsCR);
	}

	acceptTrainerDetailsCR(
		batchId: string,
		assessmentAgencyCR: ITrainerDetailsCRPatch
	) {
		// const query = {
		// 	qpCode: qpCode,
		// };
		const url = `${environment.userServiceUrl}/api/batch/v1/acceptTrainerDetailsCR/${batchId}?showServerSuccessMessage=true`;
		return this.apiService.post(url, assessmentAgencyCR);
	}

	batchRuleCheck() {
		const baAssessmentStatus: any[] = [
			B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR,
			B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR,
			B_ASSESSMENT_STATUS.ASSESSOR_CR,
		];
		const bAssessmentStatusForTPTC: any[] = [
			B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR,
		];
		function getAssessmentDateChangeValidation(
			assessmentDate: Date,
			numberOfDays?: number,
			type?: any,
			numberOfBufferDays?: any
		) {
			if (type === "increaseFromDate") {
				// const batchEditDate = assessmentDate.setDate(assessmentDate.getDate() + numberOfBufferDays + numberOfDays);
				const batchEditDate = assessmentDate.setDate(
					assessmentDate.getDate() + numberOfBufferDays
				);
				console.log(numberOfBufferDays, numberOfDays, assessmentDate);
				return new Date(batchEditDate);
			} else if (type === "increaseToDate") {
				// const batchEditDate = assessmentDate.setDate(assessmentDate.getDate() + numberOfBufferDays + numberOfDays + numberOfDays);
				const batchEditDate = assessmentDate.setDate(
					assessmentDate.getDate() + numberOfBufferDays + numberOfDays
				);
				return new Date(batchEditDate);
			} else if (type === "increaseToDateNonPMKVY") {
				const batchEditDate = assessmentDate.setDate(
					assessmentDate.getDate() + numberOfDays
				);
				return new Date(batchEditDate);
			} else {
				const batchEditDate = assessmentDate.setDate(
					assessmentDate.getDate() - numberOfDays
				);
				return new Date(batchEditDate);
			}
		}
		const DEFAULT = {
			priorAssessmentDateChangeReqFromCurrentAssessmentDate: 2,
			priorAssessorChangeReqFromCurrentAssessmentDate: 2,
			priorAssessmentAgencyReqFromCurrentAssessmentDate: 2,
			untilCanCancelBatch: 2,
			withinAssessmentDateShouldBeSelected: 2,
			noOfDaysToChangeAssessmentDateForNonPMKVY: 45,
			withinAssessmentDateShouldBeSelectedAs15: 15,
			withinAssessmentDateShouldBeSelectedForSTTSP: 30,
		};
		function alreadyUnderSomeCR(batch, type?, isCRRaisedChkAlreadyAdded?) {
			if (!batch || !batch.changeRequestStatus) {
				return false;
			}
			if (assmntStarted(batch)) {
				console.log(assmntStarted(batch));
				return true;
			}
			if (isCRRaisedChkAlreadyAdded) {
				return false;
			} else {
				if (type && type === "AAChangeReq") {
					//  || type === 'ARChanges')
					if (
						batch.jobRoles[0] &&
						batch.jobRoles[0].assessmentAgencyDetails &&
						batch.jobRoles[0].assessmentAgencyDetails.userName
					) {
						return bAssessmentStatusForTPTC.includes(
							batch.changeRequestStatus
						); // Date
					} else {
						return true;
					}
				} else if (type && type === "ARChanges") {
					if (
						batch.jobRoles[0] &&
						batch.jobRoles[0].assessmentAgencyDetails &&
						batch.jobRoles[0].assessmentAgencyDetails
							.assessorDetails &&
						batch.jobRoles[0].assessmentAgencyDetails
							.assessorDetails.userName
					) {
						return bAssessmentStatusForTPTC.includes(
							batch.changeRequestStatus
						);
					} else {
						return true;
					}
				} else {
					return baAssessmentStatus.includes(
						batch.changeRequestStatus
					);
				}
			}
		}
		function assmntStarted(batch, crType?) {
			if (
				batch &&
				batch.trackBatch &&
				(batch.trackBatch === "BATCH_CREATED" ||
					batch.trackBatch.includes("BATCH_ASSMT_RESULT_") ||
					batch.trackBatch.includes("BATCH_ASSMT_1_RESULT_") ||
					(crType === "assessmentAgencyCR" &&
						batch.trackBatch === "BATCH_AUTO_REVERSED_FROM_AA") ||
					(batch.jobRoles &&
						batch.jobRoles[0] &&
						batch.jobRoles[0].assessmentAgencyDetails &&
						batch.jobRoles[0].assessmentAgencyDetails
							.assessorDetails &&
						batch.jobRoles[0].assessmentAgencyDetails
							.assessorDetails.inTime &&
						batch.jobRoles[0].assessmentAgencyDetails
							.assessorDetails.inTime !== "" &&
						batch.jobRoles[0].assessorStatus !==
						"Marks Upload Submitted" &&
						batch.jobRoles[0].assessorStatus !==
						"Marks Upload Pending"))
			) {
				return true;
			}
		}
		function getAssessmentAgencyAssigned(batch) {
			const jobRoles = batch.jobRoles || [];
			let assessmentAgency: any;
			jobRoles.forEach((jobRole) => {
				if (assessmentAgency) {
					return;
				}
				const assessmentAgencyDetails =
					jobRole.assessmentAgencyDetails || {};
				assessmentAgency = assessmentAgencyDetails;
			});
			if (assessmentAgency.status === "ACCEPTED") {
				return assessmentAgency.userName ? assessmentAgency : null;
			}
		}
		function getAssessorAssigned(batch) {
			let assessor;
			const aa = getAssessmentAgencyAssigned(batch);
			if (!aa) {
				return assessor;
			}
			assessor = aa.assessorDetails || {};
			return assessor.userName ? assessor : null;
		}
		return {
			isBatchCanChangeAssessmentDate(
				batch,
				schemeDetails,
				type?,
				isCRRaisedChkAlreadyAdded?
			): boolean {
				// tslint:disable-next-line:max-line-length
				// const aa = getAssessmentAgencyAssigned(batch)
				// if (!aa) {
				// 	return false;
				// }
				const todayDate = new Date();
				if (
					batch &&
					batch.type &&
					(batch.type === "Fee Based" || batch.type === "feeBased")
				) {
					const validateAssessmentDateChange = new Date(
						batch.jobRoles[0].assessmentEndDate
					);
					// console.log(validateAssessmentDateChange.getTime() > todayDate.getTime())
					if (
						batch &&
						batch.trackBatch &&
						batch.trackBatch.includes("BATCH_ASSMT_") &&
						!batch.trackBatch.includes("BATCH_ASSMT_RESULT_")
					) {
						return validateAssessmentDateChange >= todayDate
							? true
							: true;
					} else {
						return false;
					}
				} else {
					const noOfDaysToChangeAssessmentDate =
						Helpers.getValueFromObject(
							schemeDetails,
							"schemeDetails.assessmentDetails.noOfDays.priorAssessmentDateChangeReqFromCurrentAssessmentDate",
							SCHEME_DAYS_COUNT.priorAssessmentDateChangeReqFromCurrentAssessmentDate
						);
					if (type) {
						const validateAssessmentDateChange = new Date(
							batch.jobRoles[0].assessmentEndDate
						);
						// if (batch && batch.bAssessmentStatus) {
						if (assmntStarted(batch, "dateCR")) {
							return false;
						} else {
							const daysDiff = Helpers.calculateDuration(
								todayDate,
								validateAssessmentDateChange
							);
							const validatedAssmntDate = new Date(
								validateAssessmentDateChange
							);
							const currentDatewithoutTime = todayDate.setHours(
								0,
								0,
								0,
								0
							);
							const assmntDatewithoutTime =
								validatedAssmntDate.setHours(0, 0, 0, 0);
							console.log(
								daysDiff,
								assmntDatewithoutTime,
								currentDatewithoutTime
							);
							console.log(
								"date compare",
								assmntDatewithoutTime >= currentDatewithoutTime
							);
							console.log(
								"already raised",
								!alreadyUnderSomeCR(
									batch,
									"",
									isCRRaisedChkAlreadyAdded
								)
							);
							if (
								batch &&
								batch.schemeType &&
								batch.schemeType === PMKVY_SCHEME_TYPES.RPL.type
							) {
								return assmntDatewithoutTime >=
									currentDatewithoutTime
									? !alreadyUnderSomeCR(
										batch,
										"",
										isCRRaisedChkAlreadyAdded
									)
									: true;
							} else {
								return assmntDatewithoutTime >=
									currentDatewithoutTime
									? !alreadyUnderSomeCR(
										batch,
										"",
										isCRRaisedChkAlreadyAdded
									)
									: batch &&
										batch.schemeType &&
										(batch.schemeType ===
											PMKVY_SCHEME_TYPES.SP.type ||
											batch.schemeType ===
											PMKVY_SCHEME_TYPES.STT.type)
										? true
										: // ? daysDiff >= 0 && daysDiff <= 450
										false; // changes from 81 to 450 days
							}
						}
						// } else {
						// 	return false;
						// }
					} else {
						const validateAssessmentDateChange =
							getAssessmentDateChangeValidation(
								new Date(batch.assessmentStartDate),
								noOfDaysToChangeAssessmentDate
							);
						// if (batch && batch.bAssessmentStatus) {
						if (assmntStarted(batch, "dateCR")) {
							return false;
						} else {
							return validateAssessmentDateChange >= todayDate
								? !alreadyUnderSomeCR(
									batch,
									"",
									isCRRaisedChkAlreadyAdded
								)
								: true;
						}
						// } else {
						// 	return false;
						// }
					}
				}
			},
			isBatchCanChangeAssessor(batch, schemeDetails, type?): boolean {
				let validateAssessmentDateChange;
				// tslint:disable-next-line:max-line-length
				const assessor = getAssessorAssigned(batch);
				if (!assessor) {
					return false;
				}
				const todayDate = new Date();
				const noOfDaysToChangeAssessmentDate =
					Helpers.getValueFromObject(
						schemeDetails,
						"schemeDetails.assessmentDetails.noOfDays.priorAssessorChangeReqFromCurrentAssessmentDate",
						SCHEME_DAYS_COUNT.priorAssessorChangeReqFromCurrentAssessmentDate
					);
				if (type) {
					validateAssessmentDateChange =
						getAssessmentDateChangeValidation(
							new Date(batch.jobRoles[0].assessmentStartDate),
							0
						);
				} else {
					validateAssessmentDateChange =
						getAssessmentDateChangeValidation(
							new Date(batch.jobRoles[0].assessmentStartDate),
							noOfDaysToChangeAssessmentDate
						);
				}
				// if (batch && batch.bAssessmentStatus) {
				const validatedAssmntDate = new Date(
					validateAssessmentDateChange
				);
				const currentDatewithoutTime = todayDate.setHours(0, 0, 0, 0);
				const assmntDatewithoutTime = validatedAssmntDate.setHours(
					0,
					0,
					0,
					0
				);
				const compareDate = validateAssessmentDateChange >= todayDate;
				const compareDateTime =
					validateAssessmentDateChange.getTime() >=
					todayDate.getTime();
				console.log(compareDate, compareDateTime);
				if (assmntStarted(batch, "assessorCR")) {
					return false;
				} else {
					return assmntDatewithoutTime >= currentDatewithoutTime
						? !alreadyUnderSomeCR(batch)
						: false;
				}
				// } else {
				// 	return false;
				// }
				// && (batch.stage !== BATCH_STAGES.ENROLLMENT)
			},
			isBatchCanChangeAssessmentAgency(
				batch,
				schemeDetails,
				type?
			): boolean {
				// tslint:disable-next-line:max-line-length
				let validateAssessmentDateChange;
				const aa = getAssessmentAgencyAssigned(batch);
				if (!aa) {
					return false;
				}
				const todayDate = new Date();
				const noOfDaysToChangeAssessmentDate =
					Helpers.getValueFromObject(
						schemeDetails,
						"schemeDetails.assessmentDetails.noOfDays.priorAssessmentAgencyChangeReqFromCurrentAssessmentDate",
						SCHEME_DAYS_COUNT.priorAssessmentAgencyReqFromCurrentAssessmentDate
					);
				if (type) {
					validateAssessmentDateChange =
						getAssessmentDateChangeValidation(
							new Date(batch.jobRoles[0].assessmentStartDate),
							0
						);
				} else {
					validateAssessmentDateChange =
						getAssessmentDateChangeValidation(
							new Date(batch.jobRoles[0].assessmentStartDate),
							noOfDaysToChangeAssessmentDate
						);
				}
				// if (batch && batch.bAssessmentStatus) {
				const currentDatewithoutTime = todayDate.setHours(0, 0, 0, 0);
				const assmntDatewithoutTime = new Date(
					validateAssessmentDateChange
				).setHours(0, 0, 0, 0);
				const compareDate = validateAssessmentDateChange > todayDate;
				const compareDateTime =
					validateAssessmentDateChange.getTime() >
					todayDate.getTime();
				console.log(compareDate, compareDateTime);
				if (assmntStarted(batch, "assessmentAgencyCR")) {
					return false;
				} else {
					return assmntDatewithoutTime >= currentDatewithoutTime
						? !alreadyUnderSomeCR(batch)
						: false;
				}
				// } else {
				// 	return false
				// }
				// && (batch.stage !== BATCH_STAGES.ENROLLMENT)
			},
			isBatchCanBeCanceled(batch, schemeDetails): boolean {
				// tslint:disable-next-line:max-line-length
				const todayDate = new Date();
				const batchStartDate = new Date(batch.batchStartDate);
				const batchEndDate = new Date(batch.batchEndDate);
				// tslint:disable-next-line:max-line-length
				const numberOfDaysToCancelBatch = Helpers.getValueFromObject(
					schemeDetails,
					"schemeDetails.batchCreationDetails.noOfDays.untilCanCancelBatch",
					SCHEME_DAYS_COUNT.untilCanCancelBatch
				);
				let batchCancelDate;
				if (batch.schemeType === PMKVY_SCHEME_TYPES.RPL.type) {
					batchCancelDate = new Date(
						batchEndDate.setDate(
							batchEndDate.getDate() - numberOfDaysToCancelBatch
						)
					); // for rpl they didn't tell about cancel batch
				} else {
					batchCancelDate = new Date(
						batchEndDate.setDate(
							batchEndDate.getDate() - numberOfDaysToCancelBatch
						)
					);
				}
				// tslint:disable-next-line:max-line-length
				return batchCancelDate.getTime() > todayDate.getTime();
			},
			isBatchCanBlockAssessor(batch, schemeDetails) {
				const assessor = getAssessorAssigned(batch);
				if (assessor) {
					return true;
				}
				return false;
			},
			isBatchCanBlockAssessmentAgency(batch, schemeDetails) {
				const aa = getAssessmentAgencyAssigned(batch);
				if (aa) {
					return true;
				}
				return false;
			},
			getAssessor(batch) {
				return getAssessorAssigned(batch);
			},
			getAssessmentAgencyAssigned(batch) {
				return getAssessmentAgencyAssigned(batch);
			},
			checkSchemeAndPMKVYVersion(batch) {
				if (
					batch.schemeBasicDetails &&
					batch.schemeBasicDetails.schemeReferenceType
						.schemeModuleVersion &&
					batch.schemeBasicDetails.schemeReferenceType.id ===
					SCHEME_REFERENCE_TYPE.PMKVY.id &&
					batch.schemeBasicDetails.schemeReferenceType
						.schemeModuleVersion ==
					PMKVY_SCHEME_VERSIONS.THREE.value &&
					(batch.schemeType === PMKVY_TRAINING_TYPES.STT.type ||
						batch.schemeType === PMKVY_TRAINING_TYPES.SP.type)
				) {
					return true;
				} else {
					return false;
				}
			},
			getAADatesToSelect(batch, schemeData, dateType?: any) {
				const today = new Date();
				const noOfDaysToChangeAssessmentDate =
					Helpers.getValueFromObject(
						schemeData,
						"schemeDetails.assessmentDetails.noOfDays.withinAssessmentDateShouldBeSelected",
						SCHEME_DAYS_COUNT.noOfDaysToChangeAssessmentDateForNonPMKVY
					);
				if (dateType === "to") {
					let dateToValidate;
					const assmntDate = new Date(
						batch.jobRoles[0].assessmentEndDate
					);
					const assmntEditDate = new Date(
						assmntDate.setDate(
							assmntDate.getDate() +
							noOfDaysToChangeAssessmentDate
						)
					);
					if (assmntEditDate >= today) {
						dateToValidate = batch.jobRoles[0].assessmentEndDate;
					} else {
						dateToValidate = today;
					}
					const validateAssessmentDateChange =
						getAssessmentDateChangeValidation(
							new Date(dateToValidate),
							noOfDaysToChangeAssessmentDate,
							"increaseToDateNonPMKVY"
						);
					return validateAssessmentDateChange;
				} else {
					const validateAssessmentDateChange =
						getAssessmentDateChangeValidation(
							new Date(),
							0,
							"increaseToDateNonPMKVY"
						);
					return validateAssessmentDateChange;
				}
			},
			getAADateBasedonSchemeRule(
				batch,
				schemeDetails,
				dateType?: any,
				rpltype?: boolean,
				categoryType?: any
			) {
				console.log(batch);
				const noOfDaysToChangeAssessmentDate =
					Helpers.getValueFromObject(
						schemeDetails,
						"schemeDetails.assessmentDetails.noOfDays.withinAssessmentDateShouldBeSelected",
						SCHEME_DAYS_COUNT.withinAssessmentDateShouldBeSelected
					);
				const noOfBufferForAssementPostBatchCompletion =
					Helpers.getValueFromObject(
						schemeDetails,
						"schemeDetails.assessmentDetails.noOfDays.noOfBufferForAssementPostBatchCompletion",
						SCHEME_DAYS_COUNT.withinAssessmentDateShouldBeSelected
					);
				const pmkvyVersion =
					batch.schemeBasicDetails &&
						batch.schemeBasicDetails.schemeReferenceType &&
						batch.schemeBasicDetails.schemeReferenceType
							.schemeModuleVersion &&
						batch.schemeBasicDetails.schemeReferenceType
							.schemeModuleVersion ==
						PMKVY_SCHEME_VERSIONS.THREE.value
						? batch.schemeBasicDetails.schemeReferenceType
							.schemeModuleVersion
						: "non3.0";
				// const categoryType = getBatchCategory(batch);
				// const RPLAssessmentDate =batchPath && batchPath.length > 0 ?
				// batchPath[batchPath.length - 1].assessmentStartDate : batch.jobRoles[0].assessmentStartDate;
				if (dateType === "to") {
					if (categoryType && categoryType.categoryThree) {
						const validateAssessmentDateChange =
							getAssessmentDateChangeValidation(
								new Date(),
								SCHEME_DAYS_COUNT.withinAssessmentDateShouldBeSelectedAs15,
								"increaseToDate",
								0
							);
						return validateAssessmentDateChange;
					} else if (rpltype) {
						const checkSchemeDataAvalability =
							Helpers.getValueFromObject(
								schemeDetails,
								"schemeDetails.assessmentDetails.noOfDays.withinAssessmentDateShouldBeSelected"
							);
						if (
							checkSchemeDataAvalability === undefined ||
							checkSchemeDataAvalability === null ||
							!checkSchemeDataAvalability
						) {
							const assmtDate = new Date();
							const validateAssessmentDateChange =
								getAssessmentDateChangeValidation(
									assmtDate,
									SCHEME_DAYS_COUNT.withinAssessmentDateShouldBeSelectedAs15,
									"increaseToDate",
									0
								);
							return validateAssessmentDateChange;
						} else {
							const validateAssessmentDateChange =
								getAssessmentDateChangeValidation(
									new Date(),
									noOfDaysToChangeAssessmentDate,
									"increaseToDate",
									0
								);
							return validateAssessmentDateChange;
						}
					} else {
						const validateAssessmentDateChange =
							getAssessmentDateChangeValidation(
								// new Date(batch.batchEndDate), noOfDaysToChangeAssessmentDate, 'increaseToDate', 3);
								new Date(),
								SCHEME_DAYS_COUNT.withinAssessmentDateShouldBeSelectedAs15,
								"increaseToDate",
								0
							);
						return validateAssessmentDateChange;
					}
				} else {
					/// from Date
					if (
						(categoryType && categoryType.categoryThree) ||
						rpltype
					) {
						const validateAssessmentDateChange =
							getAssessmentDateChangeValidation(
								rpltype ||
									(batch.type ==
										SCHEME_REFERENCE_TYPE.PMKVY.name &&
										pmkvyVersion == "non3.0")
									? new Date()
									: new Date(
										batch.jobRoles[0].assessmentStartDate
									),
								noOfDaysToChangeAssessmentDate,
								"increaseFromDate",
								!rpltype ? 0 : 0
							);
						// const validateAssessmentDateChange = RPLAssessmentDate
						return validateAssessmentDateChange;
					} else {
						const validateAssessmentDateChange =
							getAssessmentDateChangeValidation(
								batch.type ==
									SCHEME_REFERENCE_TYPE.PMKVY.name &&
									pmkvyVersion == "non3.0"
									? new Date()
									: new Date(
										batch.jobRoles[0].assessmentStartDate
									),
								noOfDaysToChangeAssessmentDate,
								"increaseFromDate",
								0
							); // new Date(batch.batchEndDate)
						return validateAssessmentDateChange;
					}
				}
			},
		};
	}

	genrateSkillCard(canId, batchId, sectorId, jobRoleName) {
		const URL = `${environment.pdfGenerate}/api/v1/generatepdfreports/skillcard?candId=${canId}&batchId=${batchId}&sectorId=${sectorId}&jobRoleNm=${jobRoleName}`;
		return this.apiService.get(URL);
	}
	genrateSkillCardV1(canId, batchId, sectorId, jobRoleName, qpCode, version) {
		const URL = `${environment.pdfGenerate}/api/v1/generatepdfreports/skillcard?candId=${canId}&batchId=${batchId}&sectorId=${sectorId}&jobRoleNm=${jobRoleName}&qpCode=${qpCode}&version=${version}`;
		return this.apiService.get(URL);
	}

	checkTotalTargetInTcForBtach(tcId, schemeId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/tc/target/count?tcId=${tcId}&schemeId=${schemeId}`;
		return this.apiService.get(URL);
	}

	/* Create Ceremony for PMKVY Batch */
	createCeremonyBatch(batchId, data, editMode) {
		if (editMode && data && data.status === RPL_CEREMONY.CREATED) {
			const edit = "yes";
			const URL = `${environment.userServiceUrl}/api/batch/v1/update/ceremony/details?batchId=${batchId}&editCeremony=${edit}`;
			return this.apiService.patch(URL, data);
		} else {
			const URL = `${environment.userServiceUrl}/api/batch/v1/update/ceremony/details?batchId=${batchId}`;
			return this.apiService.patch(URL, data);
		}
	}

	getCeremonyList(limit: number, pageNum: number, status?, filter?, sort?) {
		const queryparams = {
			sort: sort,
			status: filter && filter.status ? filter.status : status.toString(),
			pageNo: pageNum,
			itemsPerPage: limit,
			tpId: filter && filter.tpId ? filter.tpId : "",
			tcId: filter && filter.tcId ? filter.tcId : "",
			batchId: filter && filter.batchId ? filter.batchId : "",
			tpName: filter && filter.tpName ? filter.tpName : "",
			mprGenerated:
				filter && filter.mprGenerated ? filter.mprGenerated : "",
			schemeId: filter && filter.schemeId ? filter.schemeId : "",
			approvalBasis:
				filter && filter.approvalBasis ? filter.approvalBasis : "",
		};
		const url = `${environment.userServiceUrl}/api/batch/v1/get/all/batches/ceremony/completed`;
		return this.apiService.get(url, queryparams);
	}

	getCeremonyDetails(batchId: number, status) {
		const queryparams = {
			status: status.toString(),
			batchId: batchId,
		};
		const url = `${environment.userServiceUrl}/api/batch/v1/get/all/batches/ceremony/completed`;
		return this.apiService.get(url, queryparams);
	}

	saveCeremonyComment(
		batchId: number,
		data: { status: string; pmuComments: string }
	) {
		const url = `${environment.userServiceUrl}/api/batch/v1/update/ceremony/pmuComment/and/status?batchId=${batchId}`;
		return this.apiService.patch(url, data);
	}

	saveAsDraftBatch(batchId) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1//batch/status/${batchId}/draft`;
		return this.apiService.patch(URL);
	}
	getBatchByTcId(tcId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/batches/${tcId}`;
		return this.apiService.get(URL);
	}
	drafEnrolledCandidate(payLoad, tpId, batchId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/save/draft/candidates?tpId=${tpId}&batchId=${batchId}`;
		return this.apiService.patch(URL, payLoad);
	}
	getCancelledBatch() { }

	getAssociatedTrainers(qpCode: string, qpVersion?, schemeId?, isOnlyCertifiedTrainer?) {
		let url = `${environment.userServiceUrl
			}/api/user/v1/get/trainer/by/qpcode/n/byloginrole?qpCode=${encodeURIComponent(
				qpCode
			)}&qpVersion=${qpVersion}`;
		if (schemeId) {
			url = url.concat(`&schemeId=${schemeId}`);
		}
		if (isOnlyCertifiedTrainer) {
			url = url.concat(`&isOnlyCertifiedTrainer=${isOnlyCertifiedTrainer}`);
		}
		return this.apiService.get(url);
	}

	getCandidateForReassessment(batchId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/all/not/certified/candidates?batchId=${batchId}`;
		return this.apiService.get(URL);
	}

	getPwdBatches(pageNo, itemPerpage, status?, batchId?, batchStatus?) {
		const queryParams = {};
		if (status) {
			queryParams["status"] = status;
		}
		if (batchId) {
			queryParams["batchId"] = batchId;
		}
		queryParams["status"] = batchStatus;
		queryParams["itemsPerPage"] = itemPerpage;
		queryParams["pageNo"] = pageNo;
		const URL = `${environment.userServiceUrl}/api/batch/v1/list/batches/with/pwd/candidates`;
		return this.apiService.get(URL, queryParams);
	}
	getPwdCandidates(pageNo, itemPerpage, batchId, status?) {
		const queryParams = {};
		if (status) {
			queryParams["status"] = status;
		}
		queryParams["itemsPerPage"] = itemPerpage;
		queryParams["pageNo"] = pageNo;
		queryParams["batchId"] = batchId;
		const URL = `${environment.userServiceUrl}/api/batch/v1/list/batches/with/pwd/candidates`;
		return this.apiService.get(URL, queryParams);
	}

	getDisableCandidate(pageNo, itemPerpage, batchId, status?) {
		const queryParams = {};
		if (status) {
			queryParams["status"] = status;
		}
		queryParams["itemsPerPage"] = itemPerpage;
		queryParams["pageNo"] = pageNo;
		const URL = `${environment.userServiceUrl}/api/batch/v1/list/batch/pwd/candidates?batchId=${batchId}`;
		return this.apiService.get(URL, queryParams);
	}
	approveRejectBatchCandidate(payLoad, batchId, completedForBatch?) {
		if (completedForBatch === "YES") {
			const URL = `${environment.userServiceUrl}/api/batch/v1/update/batch/pwd/candidates?batchId=${batchId}&completedForBatch=${completedForBatch}`;
			return this.apiService.patch(URL, payLoad);
		} else {
			const URL = `${environment.userServiceUrl}/api/batch/v1/update/batch/pwd/candidates?batchId=${batchId}`;
			return this.apiService.patch(URL, payLoad);
		}
	}

	getBatchSize(projectId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/all/batchsize/under/project?schemeId=${projectId}`;
		return this.apiService.get(URL);
	}

	getConsumedTarget(
		qpCode: string,
		qpVersion: string,
		projectId: any,
		spoc: string,
		batchSize?,
		subschemeId?,
		batchId?,
		isPMKVY2?
	) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.userServiceUrl}/api/batch/v1/validation?qpCode=${qpCode}&qpVersion=${qpVersion}&
		projectId=${projectId}&tcId=${spoc}&batchSize=${batchSize}&subschemeId=${subschemeId}&batchId=${batchId}&isPMKVY2=${isPMKVY2}`;
		return this.apiService.get(URL);
	}

	getDuplicateTrainerValidation(batchInfo: any) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/trainervalidation`;
		return this.apiService.post(URL, batchInfo);
	}

	getTrainersToApprove(pageNo: number, limit: number) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/list/batches/for/pmu/roles`;
		const query = {};
		Object.assign(query, {
			pageNo: pageNo,
			limit: limit,
		});
		return this.apiService.get(URL, query);
	}

	approveTrainerForBatch(data) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/update/trainer/approval/details`;
		return this.apiService.patch(URL, data);
	}

	getpmkvyBasedonSchmeBatchList(pageNo?, limit?, samplingLevel?, filter?) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/pmkvy/get/candidate/listbasedonScheme?displaytab=${samplingLevel}&pageNo=${pageNo}&limit=${limit}`;
		return this.apiService.get(URL, filter);
	}

	getPmkvyAlternateIdCancelBasedOnSchemeBatchList(pageNo?, limit?, filter?) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/list/pmkvy/alternateid/cancel/batches?pageNo=${pageNo}&limit=${limit}`;
		return this.apiService.get(URL, filter);
	}

	AlternateIdVerificationExcel() {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/aid/generateExcelAidBatchList`;
		return this.apiService.getBlob(URL, "application/vnd.ms-excel");
	}

	candidateSamplingAPI(data, samplingLevel) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/pmkvy/get/candidate/sampling/idproof?displaytab=${samplingLevel}`;
		return this.apiService.put(URL, data);
	}

	ValidateCandidatesEnroll(batchId, tpId, payload) {
		const formatPayLoad = {
			candidates: payload,
		};
		const URL = `${environment.userServiceUrl}/api/batch/v1/validate/to/be/enroll/candidates?tpId=${tpId}&batchId=${batchId}`;
		return this.apiService.patch(URL, formatPayLoad);
	}
	validateIndividualSession(batchId: any, session: any) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/update/single/session/${batchId}`;
		return this.apiService.patch(URL, session);
	}

	deleteIndividualSession(batchId: any, sessionId: any) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/delete/training/calendar/session?batchId=${batchId}&sessionId=${sessionId}`;
		return this.apiService.post(URL);
	}

	trainingCalendarSessionValidation(batchId: number) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/validation/data/session/${batchId}`;
		return this.apiService.get(URL);
	}

	checkAvailablityOfOjtInJobRole(qpCode, qpVersion) {
		const URL = `${environment.sqaServiceUrl
			}/api/sqa/qp/ojtexistence?qpCode=${encodeURIComponent(
				qpCode
			)}&version=${qpVersion}`;
		return this.apiService.get(URL);
	}

	getCandidatesForReassessment(
		pageNo,
		itemPerpage,
		batchId,
		qpCode,
		version
	) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/list/reassessment/candidates/by/fee/paid/status?version=${version}&batchId=${batchId}&qpCode=${qpCode}&status=YES&itemsPerPage=${itemPerpage}&pageNo=${pageNo}`;
		return this.apiService.get(URL);
	}

	updateReassessmentFeePaidStatus(payLoad) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/update/reassessment/fee/paid/status`;
		return this.apiService.patch(URL, payLoad);
	}
	getBioMetricAttendance(
		pageNo,
		itemsPerPage,
		batchId,
		attendanceType,
		isCssm,
		stateCode,
		filterObj?,
		isCovidUpSkilling?,
		isSkillHub?,
		isNipun?,
		isAmberSenpiper?,
		isAmberAebase?,
		isMohuaAebase?,
		isSankalpAebase?
	) {
		let paramObj = {};
		if (filterObj && filterObj.candidateId) {
			paramObj["candidateId"] = filterObj.candidateId;
		}

		if (filterObj && filterObj.attendenceStatus) {
			paramObj["attendenceStatus"] = filterObj.attendenceStatus;
		}
		paramObj["isCssm"] = isCssm;
		paramObj["stateCode"] = stateCode;

		if (isCovidUpSkilling) {
			paramObj["isCovidUpSkilling"] = isCovidUpSkilling;
		}

		if (attendanceType === "candidate" && isAmberSenpiper === "yes") {
			paramObj = {};
			let URL = `${environment.userServiceUrl}/api/batch/v1/GetAebaseAttendance?isAmberSenpiper=yes&itemsPerPage=${itemsPerPage}&pageNo=${pageNo}&batchId=${batchId}`;
			return this.apiService.get(URL, paramObj);
		}
		else if (attendanceType === "candidate") {
			let URL = `${environment.userServiceUrl}/api/batch/v1/GetAebaseAttendance?itemsPerPage=${itemsPerPage}&pageNo=${pageNo}&batchId=${batchId}&isAmberAebase=${isAmberAebase}&isMohuaAebase=${isMohuaAebase}&isSankalpAebase=${isSankalpAebase}`;
			if (isSkillHub) {
				URL = URL.concat(`&isSkillHub=${isSkillHub}`);
			}
			return this.apiService.get(URL, paramObj);
		}
		else if (attendanceType === "batch" && isNipun === "yes") {
			paramObj = {};
			let URL = `${environment.userServiceUrl}/api/batch/v1/GetBatchAebaseAttendanceReport?batchId=${batchId}&isNipun=yes&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`;
			return this.apiService.get(URL, paramObj);
		}
		else if (attendanceType === "batch" && isAmberSenpiper === "yes") {
			paramObj = {};
			let URL = `${environment.userServiceUrl}/api/batch/v1/GetBatchAebaseAttendanceReport?batchId=${batchId}&isAmberSenpiper=yes&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`;
			return this.apiService.get(URL, paramObj);
		}
		else if (attendanceType === "batch") {
			let URL = `${environment.userServiceUrl}/api/batch/v1/GetBatchAebaseAttendanceReport?batchId=${batchId}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&isAmberAebase=${isAmberAebase}&isMohuaAebase=${isMohuaAebase}&isSankalpAebase=${isSankalpAebase}`;
			if (isSkillHub) {
				URL = URL.concat(`&isSkillHub=${isSkillHub}`);
			}
			return this.apiService.get(URL, paramObj);
		}

	}

	getManualAttendance(
		pageNo,
		itemsPerPage,
		batchId,
		attendanceType,
		isCssm,
		stateCode,
		filterObj?,
		isCovidUpSkilling?
	) {
		const paramObj = {};
		if (filterObj && filterObj.candidateId) {
			paramObj["candidateId"] = filterObj.candidateId;
		}

		if (filterObj && filterObj.attendenceStatus) {
			paramObj["attendenceStatus"] = filterObj.attendenceStatus;
		}
		paramObj["isCssm"] = isCssm;
		paramObj["stateCode"] = stateCode;

		if (isCovidUpSkilling) {
			paramObj["isCovidUpSkilling"] = isCovidUpSkilling;
		}

		if (attendanceType === "candidate") {
			const URL = `${environment.userServiceUrl}/api/batch/v1/GetAebaseAttendance?itemsPerPage=${itemsPerPage}&pageNo=${pageNo}&batchId=${batchId}`;
			return this.apiService.get(URL, paramObj);
		} else if (attendanceType === "batch") {
			const URL = `${environment.userServiceUrl}/api/batch/v1/GetBatchAebaseAttendanceReport?batchId=${batchId}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`;
			return this.apiService.get(URL, paramObj);
		}
	}

	getAssessorFeedback(batchId) {
		const url = `${environment.userServiceUrl}/api/batch/v1/assessment/assessor/feedback?batchId=${batchId}`;
		return this.apiService.get(url);
	}

	getProctorFeedback(batchId) {
		const url = `${environment.userServiceUrl}/api/batch/v1/assessment/proctor/feedback?batchId=${batchId}`;
		return this.apiService.get(url);
	}

	// API : localhost:3015/api/excel-report/v1/tottoa/batch/candidates
	downloadCandidatesList(data) {
		const url =
			environment.excelService +
			"/api/excel-report/v1/tottoa/batch/candidates";
		return this.apiService.getBlobOnArguments(url, data);
	}

	downloadExcelOfTrainingRequestForTrainer(sectorId, applicantType?) {
		const url =
			environment.excelService +
			`/api/excel-report/v1/tottoa/get/trainingreq/trainer?sectorId=${sectorId}&applicantType=${applicantType}`;
		return this.apiService.getBlob(url, "application/vnd.ms-excel");
	}

	downloadExcelOfTrainingRequestForAssessor(sectorId, applicantType?) {
		const url =
			environment.excelService +
			`/api/excel-report/v1/tottoa/get/trainingreq/assessor?sectorId=${sectorId}&applicantType=${applicantType}`;
		return this.apiService.getBlob(url, "application/vnd.ms-excel");
	}

	getBatchList(pageNo, itemsPerPage, tcId, schemeId?) {
		// tslint:disable-next-line: max-line-length
		const url = `${environment.userServiceUrl}/api/batch/v1/scheme/feeBased?batchStartDate=&batchEndDate=&stage=&batchId=&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&tcId=${tcId}&schemeId=${schemeId}`;
		return this.apiService.get(url);
	}
	getFeebasedSchemeData() {
		const URL = `${environment.userServiceUrl}/api/batch/v1/feebased/schemes/list`;
		return this.apiService.get(URL);
	}

	getBatchDetailsFeeBased(batchId) {
		const url = `${environment.userServiceUrl}/api/batch/v1/batchWithCandidates/${batchId}`;
		return this.apiService.get(url);
	}
	getAllTcs() {
		const URL = `${environment.userServiceUrl}/api/user/v1/get/tc/locations/info`;
		return this.apiService.get(URL);
	}

	checkBatchEditRuleForSttAndSpBatches(batchId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/seventy/percent/check/before/update?batchId=${batchId}`;
		return this.apiService.get(URL);
	}

	getBatchListforPMU(pageno, limit, data?) {
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/list/all/batches?pageNo=${pageno}&size=${limit}&status=${data.status}&schemeType=${data.scheme}&batchType=${data.batchType}&batchId=${data.batchId}`;
		return this.apiService.get(URL);
	}

	isMultipleBatchEnroll() {
		const URL = `${environment.utilityService}/api/utils/controls/multiplebatchenroll/required`;
		return this.apiService.get(URL);
	}

	/* Get all batches in PMU */
	getAllBatchesInPMU(pageNo, itemsPerPage, filter?) {
		const query = {
			pageNo: pageNo,
			itemsPerPage: itemsPerPage,
			tpId: filter && filter.tpId ? filter.tpId : "",
			tcId: filter && filter.tcId ? filter.tcId : "",
			batchId: filter && filter.batchId ? filter.batchId : "",
			status: filter && filter.status ? filter.status : "",
			stateId: filter && filter.state ? filter.state.code : "",
			districtId: filter && filter.district ? filter.district.code : "",
			schemeRefId: filter && filter.scheme ? filter.scheme : "",
		};

		const url = `${environment.userServiceUrl}/api/batch/v1/list/batches/pmu/s`;
		return this.apiService.get(url, query);
	}

	sscCanAckFee(payload) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/update/reassessment/fee/paid/status/by/ssc`;
		return this.apiService.patch(URL, payload);
	}
	checkReAssessmentEligibility(qpCode, batchId, schemeObjId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/reassessment/validation?qpCode=${qpCode}&batchId=${batchId}&schemeObjId=${schemeObjId}`;
		// const URL = `${environment.userServiceUrl}/api/batch/v1/reassessment/validation?qpCode=${qpCode}&batchId=${batchId}`;
		return this.apiService.get(URL);
	}

	getReAssessmentBatchCandidates(batchId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/batchWithCandidates/${batchId}`;
		return this.apiService.get(URL);
	}

	// getRequestedBatchListForPMU(data, pagination?) {
	// 	// http://localhost:3000/api/user/v1/aapmu/batch/list?pageNo=&limit=&schemeObjectId=&bAStatus=
	// 	const URL = `${environment.userServiceUrl}/api/user/v1/aapmu/batch/list?pageNo=${pagination.pageNo}&limit=${pagination.limit}&
	// 	schemeObjectId=${data.schemeObjectId}&bAStatus=${data.bAssessmentSatatus}&sectorId=${data.sectorId}&subsectorId=${data.subsectorId}&batchId=${data.batchId}&jobRole=${data.jobRole}`;
	// 	return this.apiService.get(URL);
	// }

	// Commented above method to implement new requirement . Email Subject : Changes asked at APMU Dashboard

	getRequestedBatchListForPMU(data, pagination?) {
		const URL = `${environment.userServiceUrl}/api/user/v1/aapmu/batch/list?pageNo=${pagination.pageNo}&limit=${pagination.limit}&
		schemeType=${data.schemeType}&bAStatus=${data.bAssessmentSatatus}&sectorId=${data.sectorId}&subsectorId=${data.subsectorId}&batchId=${data.batchId}&jobRole=${data.jobRole}&displayTab=${data.displayTab}&
		changeReqAction=${data.changeReqaction}`;
		return this.apiService.get(URL);
	}

	getFeedbackBatchList(role, batchType) {
		// http://localhost:3000/api/batch/v1/list/assessor/feedback/batches/pmkvy?role=tc
		const URL = `${environment.userServiceUrl}/api/batch/v1/list/assessor/feedback/batches/pmkvy?role=${role}&batchType=${batchType}`;
		return this.apiService.get(URL);
	}

	getBatchDetailsAms(batchId, canId, qpCode) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/ams/getAttendanceDetails/${batchId}/${canId}?qpCode=${qpCode}`;
		return this.apiService.get(URL);
	}

	modifyCanAttendance(batchId, canId, qpCode, payLoad) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/ams/updateAttendance/${batchId}/${canId}?qpCode=${qpCode}`;
		return this.apiService.put(URL, payLoad);
	}

	modifyAmsCanAttendance(batchId, canId, qpCode, payLoad) {
		const payData = {
			bId: batchId, // batchId
			candidates: [canId], // Candidate/Applicate userName
			qp: qpCode, // Qpcode
			attendanceStatus: payLoad.attendanceChangeTo, // true - Present false - Absent
			MailOrSR: payLoad.srId, // Provide mail subject or SR id
			comments: payLoad.comments, // Comments
		};
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/ams/changeAttendanceAms`;
		// const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/pmkvy/updateAttendance/${batchId}/${canId}?qpCode=${qpCode}`;
		return this.apiService.put(URL, payData);
	}

	updateContactDetails(payLoad) {
		const URL = `${environment.userServiceUrl}/api/user/v1/ams/phoneAndEmailChange`;
		return this.apiService.post(URL, payLoad);
	}
	updateTrAssessor(payLoad, userName) {
		const URL = `${environment.userServiceUrl}/api/user/v1/ams/trainer/assessor/update?userName=${userName}`;
		return this.apiService.put(URL, payLoad);
	}
	getMprForBatch(batchId: number) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingpartner/get/MPRByBatch?batchId=${batchId}`;
		return this.apiService.get(URL);
	}

	getPwdCancelledBatches(pageNo, itemPerPage, batchId?) {
		const queryParams = {};
		if (batchId) {
			queryParams["batchId"] = batchId;
		}
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.userServiceUrl}/api/batch/v1/list/batches/with/pwd/candidates?itemsPerPage=${itemPerPage}&pageNo=${pageNo}&cancelledBatch=true`;
		return this.apiService.get(URL, queryParams);
	}

	getRemainingTargetForLocation(queryParams: RemainingTargetForLocation) {
		const query = `tpid=${queryParams.tpId}&tpRole=${queryParams.role}&
		prjtid=${queryParams.projectId}&qpCode=${queryParams.qpCode}&
		schemeid=${queryParams.schemeId}&stateID=${queryParams.stateId}&districtID=${queryParams.districtId}&
		qpVersion=${queryParams.qpVersion}`;

		const URL = `${environment.userServiceUrl}/api/batch/v1/get/pmkvy/batch/
		scheme/target/distribution?${query}`;

		return this.apiService.get(URL);
	}

	getRpl4RemainingTarget(query) {
		// tslint:disable-next-line:max-line-length

		const URL = `${environment.userServiceUrl}/api/batch/v1/get/pmkvy/rpl/four/validation/for/target?tpid=${query.tpId}&tpRole=${query.role}&prjtid=${query.projectId}&qpCode=${query.qpCode}&schemeid=${query.schemeId}&batchModeId=${query.batchModeId}&qpVersion=${query.qpVersion}`;
		return this.apiService.get(URL);
	}

	getAssesmentAgencyBasedOnLocation(data, pageNo = 1, itemsPerPage) {
		const Url = `${environment.userServiceUrl}/api/user/v1/assessmentAgencyLocationBased`;
		return this.apiService.get(Url, {
			itemsPerPage: itemsPerPage,
			pageNo: pageNo,
			state: data.state || 0,
			district: data.district || 0,
			name: data.name || "",
			userName: data.userName || "",
			type: data.type || "",
			sscName: data.ssc || "",
			biceId: data.biceId || "",
			keyword: data.keyword || "",
		});
	}

	downloadTranchExcel(stage, batchId, status, approvalType, id) {
		const URL = `${environment.paymentService}/api/v1/tranche/downloadExcel?trancheStage=${stage}&batchId=${batchId}&status=${status}&approvalType=${approvalType}&id=${id}`;
		console.log(URL);
		return this.apiService.getBlob(URL, "application/vnd.ms-excel");
	}

	downloadExcelForSscPayout(batchId, status, approvalType, schemeBifurcation) {
		const URL = `${environment.paymentService}/api/v1/tranche/downloadExcel?batchId=${batchId}&status=${status}&approvalType=${approvalType}&schemeBifurcation=${schemeBifurcation}`;
		return this.apiService.getBlob(URL, "application/vnd.ms-excel");
	}

	candidateUpdateProfile(canId, payLoad) {
		const URL = `${environment.userServiceUrl}/v1/candidates/patch/${canId}`;
		return this.apiService.patch(URL, payLoad);
	}

	updateCandidateBankDetails(canId, payLoad) {
		const URL = `${environment.userServiceUrl}/v1/candidates/patch/${canId}?updateBankDetails=YES`;
		return this.apiService.patch(URL, payLoad);
	}

	getTotalSessionHours(batchId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/sum/hours/of/session/${batchId}`;
		return this.apiService.get(URL);
	}

	changeVerificationStatus(payLoad) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/changeVerificationStatus`;
		return this.apiService.post(URL, payLoad);
	}

	getSCNdetails(SCNid) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getSCNbyId/${SCNid}`;
		console.log(URL);
		return this.apiService.get(URL);
	}

	getRulesEffectiveConfigDate() {
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/rpl/batch/start/configs/dates`;
		return this.apiService.get(URL);
	}
	downloadExcelTotbatchlist() {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/sscExcel/batchAssigned`;
		return this.apiService.getBlob(URL, "application/vnd.ms-excel");
	}

	downloadExcelTotChangeRequest() {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/sscExcel/changeRequest`;
		return this.apiService.getBlob(URL, "application/vnd.ms-excel");
	}

	downloadExcelTotResultVerification() {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/sscExcel/resultVerification`;
		return this.apiService.getBlob(URL, "application/vnd.ms-excel");
	}

	downloadBatchCandidateList(batchId) {
		const URL = `${environment.excelService}/api/excel-report/v1/tottoa/candidate/report?batchId=${batchId}`;
		return this.apiService.getBlob(URL, "candidates/vnd.ms-excel");
	}

	// Pmkvy Batch Categories for lockdown Situation start
	getBatchCategoryForLockdown(batchId) {
		const query = {
			batchId: batchId,
		};
		const URL = `${environment.userServiceUrl}/api/batch/v1//batch/category/check`;
		return this.apiService.get(URL, query);
	}
	// Pmkvy Batch Categories for lockdown Situation End
	startOrEndAssessmentForRPL(batchId, assmntTime, data) {
		const queryParams =
			"?qpCode=" + data.qpCode + "&version=" + data.version;
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/${batchId}/assessment/${assmntTime}${queryParams}`;
		return this.apiService.put(URL, "application/vnd.ms-excel");
	}
	approveTargetRevocation(data) {
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/approveTargetRevocation`;
		console.log(url);
		return this.apiService.post(url, data);
	}

	rejectTargetRevocation(data) {
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/rejectTargetRevocation`;
		return this.apiService.post(url, data);
	}

	getCandidateVerificationStatus(data) {
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getCandidateVerificationStatus/${data.canId}/${data.batchId}/${data.action}`;
		console.log(url);
		return this.apiService.get(url);
	}

	generateFeedbackReport(batchId, type) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/feedbackreport?batchId=${batchId}&type=${type}`;
		return this.apiService.getBlob(URL, "application/vnd.ms-excel");
	}
	getTcDetails(tcId) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentre/${tcId}`;
		return this.apiService.get(URL);
	}
	supriseVisitDetails(tcId) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/surprisevisitdetails?surpriseVisitId=${tcId}`;
		return this.apiService.get(URL);
	}

	downloadCandidateExcel(data) {
		const url =
			environment.pmkvycmServiceUrl +
			`/api/v2/batchservice/cm/downloadNotVerifiedCandidateDataExcel`;
		return this.apiService.getBlob(url, "application/vnd.ms-excel", data);
	}

	getCandidateVerificationDataforSCN(data) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/callValidationSummary`;
		return this.apiService.get(URL, data);
	}

	getCallValidationSingleCandidate(data) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getCallValidationForOneCandidate`;
		return this.apiService.get(URL, data);
	}

	parameterComparision(data) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getTcResidentalArea`;
		return this.apiService.get(URL, data);
	}

	scnDetailsProjectLevel(data) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/getScnOfProject`;
		return this.apiService.get(URL, data);
	}

	// Batch Training Resume API
	resumeBatchTraining(batchId: string) {
		const url = `${environment.userServiceUrl}/api/batch/v1/update/batchDesclimer?batchId=${batchId}`;
		return this.apiService.patch(url);
	}

	// Offline Assessment Approval API
	offlineAssessmentApproval(data, paramData) {
		const URL = `${environment.ToTServiceURL
			}/api/tot-toa/v1/batch/assessment/offline/approval/${paramData.batchId}?
		qpCode=${encodeURIComponent(paramData.qpCode)}&version=${paramData.version}`;
		return this.apiService.put(URL, data);
	}

	getTCDetailsById(userName) {
		const URL = `${environment.userServiceUrl}/api/user/v1/tc/data?tcId=${userName}`;
		return this.apiService.get(URL);
	}

	getNeutralCentreTypes() {
		const URL = `${environment.userServiceUrl}/api/user/v1/getTypes`;
		return this.apiService.get(URL);
	}

	updateTcDetailToBatch(param, data) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/update/tcDetails?batchId=${param.batchId}&isPMKKTc=${param.isPMKKTc}&isUpdateTCDetails=${data.isUpdateTCDetails}`;
		return this.apiService.patch(URL, data);
	}

	assessmentDocUpload(data, payloadData) {
		const URL = `${environment.ToTServiceURL
			}/api/tot-toa/v1/batch/assessment/doc/upload/${data.batchId}?
			qpCode=${encodeURIComponent(data.qpCode)}&version=${data.version}`;
		return this.apiService.put(URL, payloadData);
	}

	// Covid scheme manual attendance
	updateAttendanceManual(batchId, payload, isSkillHub?) {
		let url = `${environment.userServiceUrl}/api/batch/v1/update/candidateAttendanceStatus?batchId=${batchId}`;
		if (isSkillHub) {
			url = url.concat(`&isSkillHub=${isSkillHub}`);
		}
		return this.apiService.post(url, payload);
	}

	getCandidateData(filter?) {
		let payload = filter;
		// const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/candidate/getcandidates?type=${payload.type}&batchId=${payload.batchId}`;
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/candidate/getcandidates`;
		return this.apiService.get(url, payload);
	}

	// Get SSC Certification marksheet data
	getSscCertMarkData(pageNo, itemsPerPage, filter?) {
		const query = {
			pageNo: pageNo,
			itemsPerPage: itemsPerPage,
		};
		Object.assign(query, filter);
		const url = `${environment.batchServiceUrl}/api/v1/cert/candidateAdmin/candidateData`;
		return this.apiService.get(url, query);
	}

	// Get SSC Certification marksheet bulk Download data
	getSscCertMarkBulkDownload(payload) {
		// const url = `${environment.certBulkService}/api/v1/cert/candidateAdmin/bulkreq`;
		const url = `${environment.batchServiceUrl}/api/v1/cert/candidateAdmin/bulkreq`;
		return this.apiService.post(url, payload);
	}

	getDashboardCountCMAHead() {
		// const url = `${environment.certBulkService}/api/v1/cert/candidateAdmin/bulkreq`;
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/dashbordcount`;
		return this.apiService.get(url);
	}

	getPendingActionbyBusiness(param) {
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/pendigactionbybussiness`;
		return this.apiService.get(url, param);
	}

	getPendingActionbyFinance(param) {
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/pendingactionbyfinance`;
		return this.apiService.get(url, param);
	}

	genrateMarksheetForC4W(data) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/marksheet/excelMarksheet?for=pmkvy&attendanceStatus=present`;
		return this.apiService.post(URL, data);
	}

	genrateCertificateForC4W(data) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate/excelCertificate?for=pmkvy&attendanceStatus=present`;
		return this.apiService.post(URL, data);
	}

	getAadarshGramBatches(queryParams) {
		const url = `${environment.batchServiceUrl}/api/v1/aadarsh/batches?${queryParams}`;
		return this.apiService.get(url);
	}

	updateCandidateProfilePhoto(payLoad, isFromTcTpOrAmsProfileUpload?) {
		let URL = `${environment.userServiceUrl}/v1/candidates/batch/uploadCandprofilephoto`;
		if(isFromTcTpOrAmsProfileUpload){
			URL += `?isTcOrTpUploadProfilePic=${isFromTcTpOrAmsProfileUpload}`
		}
		return this.apiService.put(URL, payLoad);
	}

	excelUpload(file, sender, userName?) {
		let url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/excelupload/readexcel?sender=${sender}`;
		if(userName){
			url += `&userName=${userName}`;
		}
		const formData = new FormData();
		formData.append('File', file);
		return this.apiService.post(url, formData);
	}

	dbtBharatExcelUpload(file, finalObj, userName?) {
		let url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/excelupload/dbtexcelUpload?userName=${userName}&year=${finalObj.year}&month=${finalObj.month}`;
		if(finalObj){
			url += `&schemeCode=${finalObj.schemeCode}`;
			// url += `year=${finalObj.year}`;
			// url += `month=${finalObj.month}`
		}
		const formData = new FormData();
		formData.append('File', file);
		return this.apiService.post(url, formData);
	}

	updateCandidateBankDetailsLatest(canId, batchId, payLoad) {
		const URL = `${environment.userServiceUrl}/v1/candidates/bankUpdate/${canId}/${batchId}`;
		return this.apiService.put(URL, payLoad);
	}

	getAllRTDBatchesInPMU(pageNo, itemsPerPage, filter?) {
		const query = {
			pageNo: pageNo,
			itemsPerPage: itemsPerPage,
			tpId: filter && filter.tpId ? filter.tpId : "",
			tcId: filter && filter.tcId ? filter.tcId : "",
			batchId: filter && filter.batchId ? filter.batchId : "",
			status: filter && filter.status ? filter.status : "",
		};

		const url = `${environment.userServiceUrl}/api/batch/v1/fetch/batches/rtd`;
		return this.apiService.get(url, query);
	}

	getAllBatchesByStates(pageNo, itemsPerPage, filter?) {
		const query = {
			pageNo: pageNo,
			itemsPerPage: itemsPerPage,
			tpId: filter && filter.tpId ? filter.tpId : "",
			tcId: filter && filter.tcId ? filter.tcId : "",
			batchId: filter && filter.batchId ? filter.batchId : "",
			status: filter && filter.status ? filter.status : "",
			schemeName: filter?.scheme?.name || "",
			id: filter?.scheme?.id || "",
			subSchemeID: filter?.subScheme?.id || "",
			trainingTypeId: filter?.trainingType?.id || "",
			subSchemeName: filter?.subScheme?.name || "",
			trainingType: filter?.trainingType?.name || "",
		};

		const url = `${environment.userServiceUrl}/api/batch/v1/list/batchesByStates`;
		return this.apiService.get(url, query);
	}

	downloadBlockchainZipFile(payload) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate/blockchainZip/download?_id=${payload._id}`;
		return this.apiService.get(URL)
	}

	// downloadExcelTemplate(userName) {
	// 	const URL = `${environment.userServiceUrl}/api/user/v1/ministry/report/${userName}`;
	// 	return this.apiService.post(URL,{})
	// }

	pmkvyFinanceBatchesExcelReport(payload) {
		const URL = `${environment.excelService}/api/excel-report/v1/tottoa/get/batches`;
		return this.apiService.getBlob(URL, "application/vnd.ms-excel", payload);
	}


	getExcelDownloadForNullResponse() {
		const URL = `${environment.paymentService}/api/v1/tranche/excel/fornullresponse`;
		console.log(URL);
		return this.apiService.getBlob(URL, "application/vnd.ms-excel");
	}

	selectedTemplateUpdateInBatch(payload) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/ncvettemplatefeebased/ncvettemplateselected`
		return this.apiService.post(URL, payload);
	}

	// To regenrate certificates
	regenerateCertBasedOnBatchIdAndCanId(batchId, canId) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/regenrate/singlecert?batchId=${batchId}&userName=${canId}`;
		return this.apiService.post(URL, {});
	}
	
	checkProfilePictureCandidateForNcvetSelection(payload) {
		const URL = `${environment.batchServiceUrl}/api/v1/cert/certificate/checkCanProfilePics?for=feeBased`
		return this.apiService.post(URL, payload);
	}
	checkExistingBatchPendingOnAssessemtn(payload){
		const URL = `${environment.userServiceUrl}/api/batch/v1/block/batchcreation/qpcode/version`
		return this.apiService.post(URL, payload);
	}

	takeActionOnCmaSpocRequestInCmaHead(body: any) {
		let url;
		url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/cmaheadaction`;
		return this.apiService.post(url, body);
	}
}

export interface RemainingTargetForLocation {
	tpId: string;
	role: string;
	projectId: string;
	qpCode: string;
	schemeId: string;
	stateId?: string;
	districtId?: number;
	qpVersion?: number;
}
